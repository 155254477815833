import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import React, { createContext, useEffect, useState, lazy } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import PaymentCheck from "./components/userSettings/PaymentCheck";
import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar";
import Checkonline from "./components/Checkonline";
import { errorHandler } from "./api/Api";
import GDPR from "./layout/GDPR";
import Spinner from "./components/Spinner";
import NetworkError from "./components/NetworkError";
import AOS from "aos";
import "aos/dist/aos.css";
import Sidebar from "./layout/Sidebar";
import { toast } from "react-toastify";
import endpoints, { HANDLE_API_REQUEST } from "./api/EndPoints";
import { socketConnection } from "./utils/socketUtils";
import OneSignal from "react-onesignal";
import { customToasterStyle } from "./utils/constants";
import PublicRoutes from "./routes/PublicRoutes";
import MemberRoutes from "./routes/MemberRoutes";
import SuccessStoryPage from "./components/publicpage/SuccessStoryPage";
import PublicPage from "./components/publicpage/PublicPage";
import Forgotpassmsg from "./authentication/ForgotPassMsg";
import ContactReceipt from "./components/userSettings/membersettings/ContactReceipt";
import BlogList from "./components/publicpage/BlogList";
import Blog from "./components/publicpage/Blog";
import ConfirmEmailMsg from "./authentication/ConfirmEmailMsg";
import SiteStartLanguage from "./components/SiteStartLanguage";
import FAQ from "./components/publicpage/FAQ";
import Notfound from "./components/Notfound";
import SomethingWrong from "./components/SomethingWrong";
import TooManyRequests from "./components/TooManyRequests";
import Maintenance from "./components/Maintenance";
import MaintenanceChecker from "./components/MaintenanceChecker";
const VerifyConfirmEmail = lazy(() =>
  import("./authentication/VerifyConfirmEmail")
);

const Resetpassword = lazy(() => import("./authentication/Resetpassword"));
export const MainContent = createContext();

function App() {
  //const history = useNavigate();
  const { t } = useTranslation();
  const [grid, setGrid] = useState(true);
  const [checkleft, setCheckleft] = useState(true);
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [changnow, setChangnow] = useState();
  const [updateMailbadge, setUpdateMailbadge] = useState(false);
  const [advancesho, setAdvancesho] = useState(true);
  const [changeimage, setChangeimage] = useState(false);
  const [show, setShow] = useState(true);
  const [checkResponse, setCheckResponse] = useState(null);
  const [footerShow, setFooterShow] = useState(true);
  const [openReg, setOpenReg] = useState(false);
  const { i18n } = useTranslation();
  const [myown_data, setMyown_data] = useState(null);
  const [language, setLanguage] = useState("en");
  const [basicshow, setBasicshow] = useState(true);
  const [isProfileImagesPublic, setIsProfileImagesPublic] = useState(true);
  const [isMemberPlatinum, setIsMemberPlatinum] = useState(false);
  const [open, setOpen] = useState(false);
  const [freshReload, setFreshReload] = useState(false); // Updates current userdata
  const [isCurrentProfileApproved, setIsCurrentProfileApproved] =
    useState(false);
  const [isCurrentProfileAvailable, setIsCurrentProfileAvailable] =
    useState(false);
  const [isMarkedForDeletion, setIsMarkedForDeletion] = useState(false);
  const [ownerEmail, setOwnerEmail] = useState("");
  const [newMessage, setNewMessage] = useState(0);
  const [pushNotificationEnabled, setPushNotificationEnabled] = useState(false);
  const [currentGender, setCurrentGender] = useState(null);
  const [isMailNotificationInitialized, setIsMailNotificationInitialized] =
    useState(false);
  // const [isMaintenance, setIsMaintenance] = useState(false);
  const showtoast = (result, successText, errorText) => {
    if (result.success) {
      toast.success(t(successText || "Toast_Successfully_Saved"), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(t(errorText || "Toast_Saving_Unsuccessful"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };

  const changelanguage = (e) => {
    // if (language === "ar") {
    //   changeLanguagrToArabic();
    // }

    i18n.changeLanguage(e);
  };
  const handleSetSiteSettings = ({
    defaultlanguage,
    defaultstartuppage,
    defaultcurrency,
  }) => {
    let data = { ...myown_data };
    data.data.profileattributes.defaultstartuppage = defaultstartuppage;
    data.data.profileattributes.defaultcurrency = defaultcurrency;
    data.data.profileattributes.defaultlanguage = defaultlanguage;
    setMyown_data(data);
    setIsProfileImagesPublic(data?.data?.albumcontainer?.ispublic ?? true);
    setLanguage(defaultlanguage);

    defaultlanguage !== "ar"
      ? changeDirectionsame(defaultlanguage)
      : changeDirection(defaultlanguage);
    localStorage.setItem("i18nextLng", defaultlanguage);
  };

  const changeLanguagrToArabic = () => {
    const rootElement = document.getElementById("root");
    rootElement.style.cssText = `
    direction: rtl;
    font-family: 'Noto Kufi Arabic', sans-serif;
    font-size: 15px;
  `;
  };
  const changeDirection = (e) => {
    if (e === "ar") {
      changelanguage(e);
      changeLanguagrToArabic();
      setCheckleft(false);
      setLanguage(e);
    }
  };

  const changeDirectionsame = (e) => {
    if (e !== "ar") {
      changelanguage(e);
      document.getElementById("root").style.direction = "ltr";
      document.getElementById("root").style.fontFamily = "Roboto, sans-serif";
      document.getElementById("root").style.fontSize = "15px";
      setCheckleft(true);
      setLanguage(e);
    }
  };
  // const getMaintenanceStatus = async () => {
  //   try {
  //     const response = await HANDLE_API_REQUEST.GET(
  //       endpoints.GET_MAINTENANCE_STATUS,
  //       language
  //     );

  //     //errorHandler(response); // Handle any errors if needed
  //     const data = await response.json(); // Get the response body as JSON
  //     setIsMaintenance(data.maintenance);
  //     return data.maintenance; // Assuming the API returns a boolean value for maintenance mode
  //   } catch (error) {
  //     console.error("Error fetching maintenance status:", error);
  //     return false; // Return false if there's an error
  //   }
  // };

  // useEffect(() => {
  //   const checkMaintenance = async () => {
  //     await getMaintenanceStatus();

  //     if (isMaintenance) {
  //       window.location.href = "/maintenance";
  //     }
  //   };

  //   checkMaintenance();
  // }, [isMaintenance]);

  // useEffect(() => {
  //   const checkMaintenance = async () => {
  //     try {
  //       const response = await HANDLE_API_REQUEST.GET(
  //         endpoints.GET_MAINTENANCE_STATUS,
  //         language
  //       );
  //       const data = await response.json();

  //       if (data.maintenance) {
  //         window.location.href = "/maintenance";
  //       }
  //     } catch (error) {
  //       console.error("Error fetching maintenance status:", error);
  //     }
  //   };

  //   checkMaintenance();
  // }, []); // Kører kun én gang ved mount

  const getOwnData = () => {
    HANDLE_API_REQUEST.GET(endpoints.GET_OWN_DATA, language)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setMyown_data(result);
        setIsProfileImagesPublic(
          result?.data?.albumcontainer?.ispublic ?? true
        );
        setIsCurrentProfileApproved(result.data?.profileattributes.approved);
        setIsCurrentProfileAvailable(result.data?.profileattributes.available);
        setIsMemberPlatinum(
          result.data?.profileattributes.subscriptiontype === "Platinum"
        );
        setIsMarkedForDeletion(
          result.data?.profileattributes.markedfordeletion
        );
        setOwnerEmail(result.data?.profileattributes.email);
        setPushNotificationEnabled(
          result.data?.profileattributes.pushnotificationenabled
        );
        setCurrentGender(result.data?.profileattributes.gender);
        const lang = localStorage.getItem("i18nextLng");

        if (result.data.profileattributes) {
          if (lang !== null && lang !== undefined) {
            changelanguage(lang);
          } else if (
            result.data.profileattributes.defaultlanguage !== null &&
            result.data.profileattributes.defaultlanguage !== undefined
          ) {
            changelanguage(result.data.profileattributes.defaultlanguage);
          } else {
            changelanguage("en");
          }
        }
      });
  };

  useEffect(() => {
    if (freshReload === true) {
      getOwnData();
      setFreshReload(false);
    }
  }, [freshReload, language]);

  const refreshTime = process.env.REACT_APP_REFRESH_TIME;

  const [handleReload, setHandleReload] = useState(false);

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng") || "en";

    if (lang === "ar") {
      changelanguage("ar");
      setLanguage("ar");
      setCheckleft(false);
      // document.getElementById("root").style.direction = "rtl";
      // document.getElementById("root").style.fontFamily =
      //   "Noto Kufi Arabic, sans-serif;";
      // document.getElementById("root").style.fontSize = "15px";
      changeLanguagrToArabic();
    }

    if (lang !== "ar") {
      changelanguage(lang);
      setLanguage(lang);
      document.getElementById("root").style.fontFamily = "Roboto, sans-serif";
      document.getElementById("root").style.fontSize = "15px";
    }

    const declineCookie = Cookies.get("decline");
    const acceptCookie = Cookies.get("accept");

    if (declineCookie || acceptCookie) {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    if (myown_data) {
      //Used by SignalR
      if (myown_data !== null && myown_data !== undefined) {
        const oneMonthFromNow = new Date();
        oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
        Cookies.set("userId", myown_data.data.profileid, {
          expires: oneMonthFromNow,
        });
      }
    }
  }, [myown_data]);

  // check if accesstoken valid
  useEffect(() => {
    let timeoutId;
    if (Cookies.get("accessToken") && Cookies.get("refreshToken")) {
      const token = Cookies.get("accessToken");
      const refreshToken = Cookies.get("refreshToken");
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
      myHeaders.append("Accept-Language", language || "en");

      const body = JSON.stringify({
        token: token,
        refreshToken: refreshToken,
      });

      const config = {
        method: "POST",
        headers: myHeaders,
        body: body,
        redirect: "follow",
      };
      HANDLE_API_REQUEST.POST(endpoints.REFRESH_TOKEN, null, config)
        .then((response) => {
          setCheckResponse(response);
          return response.json();
        })
        .then((result) => {
          if (result.succeeded) {
            // Set cookies to expire after one month
            const oneMonthFromNow = new Date();
            oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

            Cookies.set("accessToken", result.token, {
              expires: oneMonthFromNow,
            });
            Cookies.set("refreshToken", result.refreshtoken, {
              expires: oneMonthFromNow,
            });
            Cookies.set("isAuthenticated", result.succeeded, {
              expires: oneMonthFromNow,
            });
            setChangnow(result.token);
            getOwnData();
            setisAuthenticated(result.succeeded);
            localStorage.setItem("isAuthenticated", true);

            timeoutId = setTimeout(() => {
              setHandleReload(!handleReload);
            }, refreshTime);
          } else {
            setisAuthenticated(false);
            Cookies.set("isAuthenticated", false);
          }
        })
        .catch((err) => {
          errorHandler(err);
        });
    } else {
      setisAuthenticated(false);
      Cookies.set("isAuthenticated", false);
      setCheckResponse(true);
    }

    if (isAuthenticated) {
      const signalrWebsocket = socketConnection();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isAuthenticated, handleReload]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const deleteOneSignalPushNotification = async (token) => {
    try {
      const body = JSON.stringify({
        fcmtoken: token,
      });

      const response = await HANDLE_API_REQUEST.DELETE(
        endpoints.DELETE_ONESIGNAL_PUSHNOTIFICATION,
        body
      );
      errorHandler(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleOneSignalPushNotification = async (
    token,
    platform,
    subscriptionid
  ) => {
    try {
      const body = JSON.stringify({
        fcmtoken: token,
        platform: platform,
        subscriptionid: subscriptionid,
      });

      const response = await HANDLE_API_REQUEST.POST(
        endpoints.SAVE_ONESIGNAL_PUSHNOTIFICATION,
        body
      );
      errorHandler(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const initializeOneSignal = async () => {
      if (isAuthenticated) {
        const translations = {
          // Arabic
          ar: {
            actionMessage:
              "اشترك في إشعاراتنا للحصول على آخر الأخبار والتحديثات. يمكنك إيقافها في أي وقت.",
            acceptButton: "اشترك",
            cancelButton: "لا، شكرًا",
          },
          // Italien
          it: {
            actionMessage:
              "Iscriviti alle nostre notifiche per le ultime notizie e aggiornamenti. Puoi disattivarle in qualsiasi momento.",
            acceptButton: "Iscriviti",
            cancelButton: "No, grazie",
          },
          // Danish
          da: {
            actionMessage:
              "Abonner på vores notifikationer for de seneste nyheder og opdateringer. Du kan deaktivere dem når som helst.",
            acceptButton: "Abonner",
            cancelButton: "Nej tak",
          },
          // Norwegian
          no: {
            actionMessage:
              "Abonner på våre varsler for de siste nyhetene og oppdateringene. Du kan deaktivere dem når som helst.",
            acceptButton: "Abonner",
            cancelButton: "Nei takk",
          },
          // Sweedish
          sv: {
            actionMessage:
              "Prenumerera på våra aviseringar för de senaste nyheterna och uppdateringarna. Du kan inaktivera dem när som helst.",
            acceptButton: "Prenumerera",
            cancelButton: "Nej tack",
          },
          // Turkish
          tr: {
            actionMessage:
              "Son haberleri ve güncellemeleri almak için bildirimlerimize abone olun. İstediğiniz zaman devre dışı bırakabilirsiniz",
            acceptButton: "Abone Ol",
            cancelButton: "Hayır teşekkürler",
          },
          // Dutch
          nl: {
            actionMessage:
              "Abonneer je op onze meldingen voor het laatste nieuws en updates. Je kunt ze op elk moment uitschakelen.",
            acceptButton: "Abonneren",
            cancelButton: "Nee, bedankt",
          },
          // German
          de: {
            actionMessage:
              "Abonnieren Sie unsere Benachrichtigungen für die neuesten Nachrichten und Updates. Sie können sie jederzeit deaktivieren.",
            acceptButton: "Abonnieren",
            cancelButton: "Nein danke",
          },
          // French
          fr: {
            actionMessage:
              "Abonnez-vous à nos notifications pour les dernières nouvelles et mises à jour. Vous pouvez désactiver à tout moment.",
            acceptButton: "S'abonner",
            cancelButton: "Non merci",
          },
          // Spanish
          es: {
            actionMessage:
              "Suscríbete a nuestras notificaciones para recibir las últimas noticias y actualizaciones. Puedes desactivarlo en cualquier momento.",
            acceptButton: "Suscribir",
            cancelButton: "No, gracias",
          },
          default: {
            actionMessage:
              "Subscribe to our notifications for the latest news and updates. You can disable anytime.",
            acceptButton: "Subscribe",
            cancelButton: "No thanks",
          },
        };

        //var language = navigator.language.toLowerCase();
        let translation = translations[language] || translations.default;
        let promptLangs = { ...translation };

        await OneSignal.init({
          appId: process.env.REACT_APP_ONESIGNAL_KEY,
          promptOptions: {
            slidedown: {
              prompts: [
                {
                  type: "push",
                  autoPrompt: true,
                  text: { ...promptLangs },
                },
              ],
            },
          },
        });
      }
    };

    initializeOneSignal();
  }, [isAuthenticated]);

  useEffect(() => {
    const initializeOneSignal = async () => {
      try {
        const subscriptionId = OneSignal.User.PushSubscription.id;
        const isSupported = OneSignal.Notifications.isPushSupported();
        let permission = OneSignal.Notifications.permission;

        if (
          subscriptionId &&
          isSupported &&
          permission &&
          pushNotificationEnabled
        ) {
          OneSignal.User.addAlias("external_id", subscriptionId);
          await handleOneSignalPushNotification(
            subscriptionId,
            "Web",
            subscriptionId
          );
        }
        if (!pushNotificationEnabled && subscriptionId) {
          await deleteOneSignalPushNotification(subscriptionId);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    initializeOneSignal();
  }, [pushNotificationEnabled]);

  return (
    <>
      <MainContent.Provider
        value={{
          handleSetSiteSettings,
          showtoast,
          setisAuthenticated,
          checkleft,
          setGrid,
          grid,
          changeDirection,
          changeDirectionsame,
          language,
          changnow,
          changeimage,
          setChangeimage,
          isAuthenticated,
          setShow,
          show,
          myown_data,
          basicshow,
          setBasicshow,
          setFooterShow,
          setLanguage,
          advancesho,
          setAdvancesho,
          open,
          setOpen,
          openReg,
          setOpenReg,
          setFreshReload,
          updateMailbadge,
          setUpdateMailbadge,
          setMyown_data,
          isProfileImagesPublic,
          isCurrentProfileApproved,
          isCurrentProfileAvailable,
          isMarkedForDeletion,
          ownerEmail,
          newMessage,
          setNewMessage,
          isMemberPlatinum,
          setPushNotificationEnabled,
          isMailNotificationInitialized,
          setIsMailNotificationInitialized,
          currentGender,
        }}
      >
        <MaintenanceChecker />
        {checkResponse !== null ? (
          <div>
            <Router>
              {footerShow && <Navbar />} {isAuthenticated && <Checkonline />}{" "}
              {isAuthenticated && myown_data !== null && footerShow && (
                <Sidebar />
              )}
              <>
                <Routes>
                  <Route path="/:language" element={<SiteStartLanguage />} />
                  {/* <Route path="/confirm-your-email" element={<Confirmemail />} /> */}
                  <Route
                    path="/email-confirmation"
                    element={<ConfirmEmailMsg />}
                  />
                  <Route
                    path="/forgot-password-message"
                    element={<Forgotpassmsg />}
                  />
                  <Route
                    path="/confirmemail/:userId/:token"
                    element={<VerifyConfirmEmail />}
                  />
                  <Route path="/contact-receipt" element={<ContactReceipt />} />
                  <Route
                    path="/resetpassword/:userId/:token"
                    element={<Resetpassword />}
                  />
                  <Route
                    path="/successStories"
                    element={<SuccessStoryPage />}
                  />
                  <Route path="/public-page/:page" element={<PublicPage />} />
                  <Route path="/blogs" element={<BlogList />} />
                  <Route path="/blog/:siteid" element={<Blog />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/makepayment" element={<PaymentCheck />} />
                  <Route path="/maintenance" element={<Maintenance />} />
                  <Route path="/network-error" element={<NetworkError />} />
                  <Route
                    path="/too-many-requests"
                    element={<TooManyRequests />}
                  />
                  <Route path="/error" element={<SomethingWrong />} />
                  {!isAuthenticated ? (
                    <>{PublicRoutes()}</>
                  ) : myown_data !== null ? (
                    <>{MemberRoutes()}</>
                  ) : (
                    <Route path="*" element={<Spinner />} />
                  )}
                  <Route component={Notfound} />
                  <Route path="*" element={<Notfound />} />
                </Routes>
              </>
              {show && <GDPR />}
              {footerShow && <Footer />}{" "}
            </Router>{" "}
          </div>
        ) : (
          <Spinner />
        )}{" "}
      </MainContent.Provider>
    </>
  );
}

export default App;
