import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTools } from 'react-icons/fa'; // Wrench icon for maintenance

const Maintenance = () => {
  
  const { t } = useTranslation();

  

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mt-16 lg:pt-10 min-h-screen pb-5">
      <div className="container">
        <div className="bg-white py-5 px-5 w-full shadow-md rounded-lg font-medium ">
          <div className="flex place-content-center mb-4 h3">
            <FaTools className=" text-6xl tex text-red-700" />
          </div>
          <div className=" text-sr font-medium text-center">
            {" "}
            {t("Global_Maintenance_Message")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
