import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill } from "react-icons/bs";

const Forgotpassmsg = () => {
  const { t } = useTranslation();
  return (
    <div className=" min-h-screen">
      <div className=" w-full mt-20 pt-3 ">
        <div className="container">
          <div className="ml-2 mr-2 ">
            <div className="items-center justify-center text-center mt-2 mb-8 bg-white  py-5 px-1 w-full shadow-md rounded-lg font-medium ">
              <div className="w-full h-full flex items-center justify-center mb-4">
                <BsCheckCircleFill className="text-green-600 text-6xl" />
              </div>
              <div>{t("Reset_Password_Message")}</div>
              <div className="w-full flex flex-col sm:flex-row gap-1 justify-center items-center mt-3">
                <Link
                  to="/public-contact"
                  className="px-4 py-2 rounded-md bg-sr hover:bg-pr text-white w-full sm:w-auto"
                >
                  {t("Footer_Contact_Us")}
                </Link>
                <Link
                  to="/"
                  className=" px-4 py-2 rounded-md bg-sr hover:bg-pr text-white w-full sm:w-auto"
                >
                  {t("Back_To_Home")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassmsg;
