import "bootstrap/dist/css/bootstrap.min.css";
import React, { createContext } from "react";
import { Route, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Admin from "../components/Admin/Admin";
import Activity from "../components/profile/Activity";
import Dashboard from "../components/profile/Dashboard";
import WelcomePage from "../components/profile/WelcomePage";
import Matches from "../components/profile/Matches";
import Search from "../components/profile/Search";
import PrivateProfile from "../components/profileSettings/PrivateProfile";
import ImageAccess from "../components/profileSettings/pSettingsPages/ImageAccess";
import ProfileDelete from "../components/profileSettings/pSettingsPages/ProfileDelete";
import ProfileEdit from "../components/profileSettings/pSettingsPages/ProfileEdit";
import ProfilePhotos from "../components/profileSettings/pSettingsPages/ProfilePhotos";
import PublicProfile from "../components/profileSettings/PublicProfile";
import MembersSettings from "../components/userSettings/membersettings/MemberSettings";
import Payment from "../components/userSettings/Payment";
import ChangeEmailChange from "../components/userSettings/membersettings/ChangeEmailChange";
import PaymentSuccess from "../components/userSettings/PaymentSuccess";
import PaymentWrong from "../components/userSettings/PaymentWrong";
import PaymentCancel from "../components/PaymentCancel";
import ProfileNotFound from "../components/ProfileNotFOund";
import Loader from "../components/Loader";
import ChangeEmailReceipt from "../components/userSettings/ChangeEmailReceipt";
import ChagePassReceipt from "../components/userSettings/ChangePasswordReceipt";
import Chat from "../components/chat/Chat";
import StartPage from "../components/profile/StartPage";
import ErrorBoundary from "../components/ErrorBoundary";
import PrivateContact from "../components/userSettings/membersettings/PrivateContact";
export const MainContent = createContext();

const MemberRoutes = () => (
  <>
    <Route
      exact
      path="/chat/:status"
      element={
        <ErrorBoundary>
          <Chat />
        </ErrorBoundary>
      }
    />
    <Route
      path="/chat/:status/:inboxid"
      element={
        <ErrorBoundary>
          <Chat />
        </ErrorBoundary>
      }
    />
    <Route
      exact
      path="/chat"
      element={
        <ErrorBoundary>
          <Navigate to="/chat/active" />
        </ErrorBoundary>
      }
    />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/welcomepage" element={<WelcomePage />} />
    <Route exact path="/" element={<StartPage />} />
    <Route path="/login" element={<Loader red={true} />} />
    <Route path="/search/:page/:gender" element={<Search />} />
    <Route path="/search/:page" element={<Search />} />
    <Route path="/activity/:page" element={<Activity />} />
    <Route path="/matches/:page" element={<Matches />} />
    <Route path="/profile/:profileid" element={<PublicProfile />} />
    <Route path="/profile-settings/view" element={<PrivateProfile />} />
    <Route path="/public-contact" element={<PrivateContact />} />
    <Route path="/profile-settings/edit" element={<ProfileEdit />} />
    <Route path="/profile-settings/delete" element={<ProfileDelete />} />
    <Route path="/profile-settings/photos" element={<ProfilePhotos />} />
    <Route path="/profile-settings/image-access" element={<ImageAccess />} />
    <Route path="/payment" element={<Payment />} />
    <Route path="/payment/cancel" element={<PaymentCancel />} />
    <Route path="/memberssettings/:page" element={<MembersSettings />} />
    <Route path="/admin/:page" element={<Admin />} />
    <Route
      path="/changeemailconfirm/:userid/:email/:token"
      element={<ChangeEmailChange />}
    />
    <Route path="/payment-success" element={<PaymentSuccess />} />
    <Route path="/payment-wrong" element={<PaymentWrong />} />
    <Route path="/profile-notfound" element={<ProfileNotFound />} />
    <Route path="/change-email-receipt" element={<ChangeEmailReceipt />} />
    <Route path="/change-password-receipt" element={<ChagePassReceipt />} />
  </>
);

export default MemberRoutes;
