import React, { useContext, useEffect, useState } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";
import { MainContent } from "../App";
import ProfileNavbar from "../layout/ProfileNavbar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FixedHeader from "../layout/FixedHeader";

const NetworkError = () => {
  const { isAuthenticated, language } = useContext(MainContent);
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);
  
  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mt-26 md:mt-18 lg:mt-16 lg:pt-10 min-h-screen pb-5">
      <div className="container">
        {isAuthenticated && (
          <div className="mb-2 ">
            <ProfileNavbar />
          </div>
        )}
        <FixedHeader
          scrollPosition={scrollPosition}
          headerText={t("Global_Error_Message_Header")}
          language={language}
        />
        <div className="bg-white py-5 px-1 w-full shadow-md rounded-lg font-medium ">
          <div className="flex place-content-center mb-4 h3">
            <BsExclamationCircleFill className=" text-6xl tex text-red-700" />
          </div>
          <div className=" text-sr font-medium text-center">
            {" "}
            {t("Global_Network_Error_Message")}
          </div>
          <div className="w-full flex gap-1 justify-center items-center mt-4 ">
            <Link
              to="/"
              className=" px-4 py-2 mt-2 rounded-3xl bg-sr hover:bg-pr text-white "
            >
              {t("Back_To_Home")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkError;
