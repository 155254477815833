export const fetchUserLocation = async () => {
  try {
    const response = await fetch("https://ipinfo.io?token=9cfd8badc87333");

    if (!response.ok) {
      console.warn("Failed to fetch location. Status:", response.status);
      return null;
    }

    const data = await response.json();

    if (!data || !data.city || !data.country || !data.loc) {
      console.warn("Incomplete location data:", data);
      return null;
    }

    // Extract latitude and longitude from 'loc' field
    const [latitude, longitude] = data.loc.split(",");

    return {
      city: data.city,
      country: data.country, // Country name
      countryCode: data.country, // Country code (e.g., DK)
      latitude: latitude || "", // Default empty string if missing
      longitude: longitude || "", // Default empty string if missing
    };
  } catch (error) {
    console.error("Error fetching location:", error);
    return null; // Return null to avoid breaking app functionality
  }
};
