import Cookies from "js-cookie";
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
  Carousel,
  Card,
} from "react-bootstrap";
import {
  MdLocationOn,
  MdOutlineRecommend,
  MdSportsKabaddi,
} from "react-icons/md";
import { FiActivity } from "react-icons/fi";
import { RiGenderlessLine } from "react-icons/ri";
import { useParams, Link, useNavigate } from "react-router-dom";
import { url } from "../../api/Api";
import { MainContent } from "../../App";
import ProfileNavbar from "../../layout/ProfileNavbar";
import muslimf from "../../images/avatar_female.jpg";
import noimage from "../../images/avatar_male.jpg";
import "./psetting.css";
import { AiFillCloseCircle, AiOutlineWarning } from "react-icons/ai";
import { SiInformatica, SiLooker } from "react-icons/si";
import {
  BsPersonBoundingBox,
  BsPersonLinesFill,
  BsTrophyFill,
} from "react-icons/bs";
import { GoInfo } from "react-icons/go";
import { IoImageOutline } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";
import cover from "../../images/Image2.jpg";
import gold from "../../images/gold.png";
import basic from "../../images/basic.png";
import platinum from "../../images/platinum.png";
import { GiBackup, GiHobbitDwelling } from "react-icons/gi";
import {
  FaAmericanSignLanguageInterpreting,
  FaGlassCheers,
  FaUserEdit,
  FaStreetView,
  FaIdCard,
} from "react-icons/fa";
import fetchHandler from "../../api/fetchHandler";
import MakeRow from "./MakeRow";
import Swal from "sweetalert2";
import { customToasterStyle } from "../../utils/constants";
import Flag from "react-world-flags";
import { FaRegCopy, FaCopy } from "react-icons/fa6";

const PublicProfile = () => {
  const { t } = useTranslation();
  const {
    language,
    changnow,
    myown_data,
    isCurrentProfileApproved,
    isCurrentProfileAvailable,
    isMarkedForDeletion,
  } = useContext(MainContent);
  const { profileid } = useParams();
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState();
  const [userid, setUserid] = useState("");
  const [location, setLocation] = useState("");
  const [title, setTitle] = useState("");
  const [aboutme, setAboutme] = useState(t(""));
  const [countryorigin, setCountryorigin] = useState(null);
  const [countryoriginCode, setCountryoriginCode] = useState(null);
  const [country, setCountry] = useState(null);
  const [looking, setlooking] = useState(t(""));
  const [body, setBody] = useState("");
  const [formdata, setFormdata] = useState();
  const [getowndata, setGetowndata] = useState();
  const [profileattributes, setProfileattributes] = useState();
  const [albumcontainer, setAlbumcontainer] = useState();
  const [backgroundandculturalvalues, setBackgroundandculturalvalues] =
    useState();
  const [bodyshapedescription, setBodyshapedescription] = useState();
  const [lifestylesandattitudes, setLifestylesandattitudes] = useState();
  const [profilehobbies, setProfilehobbies] = useState();
  const [personalitydescription, setPersonalitydescription] = useState();
  const [profilepicture, setProfilepicture] = useState();
  const [thumbnileimages, setThumbnileimages] = useState([]);
  const [mainImages, setmainImages] = useState([]);
  const [spokenlanguages, setSpokenlanguages] = useState();
  const [myid, setMyid] = useState();
  const [hobbies, setHobbies] = useState();
  const [ispaid, setIspaid] = useState(false);
  const [sendmessage, setSendmessage] = useState(false);
  const [reload, setReload] = useState(true);
  const [ppublic, setPpublic] = useState(true);
  const history = useNavigate();
  const [viewpic, setViewpic] = useState([]);
  const [sports, setSports] = useState([]);
  const [traveling, setTraveling] = useState([]);
  const [viewPhofilePhoto, setViewPhofilePhoto] = useState(false);
  const [mappedUsers, setMappedUsers] = useState([]);
  const imageOnError = (event) => {
    event.currentTarget.src = gender ? noimage : muslimf;
  };

  const imageSrc = gender ? noimage : muslimf;
  const [valueCopied, setValueCopied] = useState(false);
  const textRef = useRef(null);

  // const handleCopyClick = () => {
  //   const range = document.createRange(); // Create a range
  //   range.selectNodeContents(textRef.current); // Select the contents of <span>

  //   const selection = window.getSelection(); // Get the current selection
  //   selection.removeAllRanges(); // Clear any existing selection
  //   selection.addRange(range); // Add the new range to selection

  //   try {
  //     document.execCommand("copy");
  //     setValueCopied(true); // Copy the selected text
    //   } catch (err) {
  //     console.error("Failed to copy text: ", err);
  //   }

  //   selection.removeAllRanges(); // Deselect the text
  // };
  const handleCopyClick = async () => {
    try {
      // Use the Clipboard API to copy text to the clipboard
      await navigator.clipboard.writeText(textRef.current.innerText);
      setValueCopied(true); // Set state to indicate that the value has been copied
      toast.success(t("CopiedToClipboard"), {
        autoClose: 3000,
      });
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  function photomix(pic) {
    if (pic.isthumbnailblurred && mainImages.length !== 0) {
      setViewpic(mainImages);
    } else {
      const selectedPicture = mainImages.filter(
        (item) => item.pictureid === pic.parentpictureid
      );
      const oldv = mainImages.filter((item) => item !== selectedPicture[0]);
      const newv = [selectedPicture[0], ...oldv];
      setViewpic(newv);
    }
  }

  function PeoplePhoto(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Carousel>
            {viewpic.map((item, ind) => (
              <Carousel.Item key={ind}>
                <img
                  style={{ width: "100%", height: "450px" }}
                  src={`${process.env.REACT_APP_IMAGE}${item.path}`}
                  onError={(event) => (event.target.style.display = "none")}
                  alt=""
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    );
  }
  function ProfilePhoto(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <h6 className={language === "ar" ? "py-2 text-right w-full" : ""}>
            <span className="font-bold">{t("Main_Profile_Picture")}</span>
          </h6>{" "}
        </Modal.Header>
        <Modal.Body>
          {albumcontainer !== null ? (
            albumcontainer?.pictures.length !== 0 && (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                className="cursor-pointer"
                src={`${process.env.REACT_APP_IMAGE}${profilepicture?.path}`}
                alt=""
                onError={imageOnError}
              />
            )
          ) : (
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              className="rounded-full cursor-pointer"
              src={imageSrc}
              onError={imageOnError}
              alt=""
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  myHeaders.append("Accept-Language", language || "en");

  const getDataFromServer = async () => {
    fetchHandler(
      `${url}metadata/lookups?LookupOptions=Profile`,
      "GET",
      undefined,
      myHeaders
    ).then((response) => {
      setFormdata(response);
    });
  };
  const getProfileHobbies = async () => {
    fetchHandler(
      `${url}metadata/lookups?LookupOptions=Hobbies`,
      "GET",
      undefined,
      myHeaders
    ).then((response) => {
      setHobbies(response);
    });
  };
  const getPublicData = () => {
    if (myown_data.data.profilecontent !== null) {
      setTitle(myown_data.data.profilecontent.title);
      setAboutme(myown_data.data.profilecontent.aboutmetext);
      setlooking(myown_data.data.profilecontent.lookingfortext);
    }

    // set all the information
    setUsername(myown_data.data.profileattributes.displayusername);

    setUserid(myown_data.data.profileid);
    if (myown_data.data.location !== null) {
      setCountry(myown_data.data.location.countryofresidenceid);
      setCountryorigin(myown_data.data.location.countryoforiginid);
      setLocation(myown_data.data.location.alternatecityname);
      setCountryoriginCode(myown_data.data.location.countryoforigincode);
    }
    setGender(myown_data.data.profileattributes.gender);
    setBodyshapedescription(myown_data.data.bodyshapedescription);
    setProfileattributes(myown_data.data.profileattributes);

    setProfilehobbies(myown_data.data.profilehobbies);
    setPersonalitydescription(myown_data.data.personalitydescription);
    setBackgroundandculturalvalues(myown_data.data.backgroundandculturalvalues);
    setAlbumcontainer(myown_data.data.albumcontainer);
    if (myown_data.data.albumcontainer !== null) {
      setPpublic(myown_data.data.albumcontainer.ispublic);
      if (myown_data.data.albumcontainer.pictures.length !== 0) {
        const profilepic = myown_data.data.albumcontainer.pictures.filter(
          (item) =>
            item.ismicroimage === false &&
            item.isprimary === true &&
            item.isthumbnail === false
        );
        const thumbniles = myown_data.data.albumcontainer.pictures.filter(
          (item) =>
            item.isprimary === false &&
            item.isthumbnail === true &&
            item.ismicroimage === false &&
            item.approved === true
        );
        const profileMainImages =
          myown_data.data.albumcontainer.pictures.filter(
            (item) =>
              item.isprimary === false &&
              item.isthumbnail === false &&
              item.ismicroimage === false &&
              item.approved === true
          );
        setmainImages(profileMainImages);
        setThumbnileimages(thumbniles);
        setProfilepicture(profilepic[0]);
      } else {
        setThumbnileimages([]);
        setmainImages([]);
      }
    } else {
      setThumbnileimages([]);
      setmainImages([]);
    }
    setLifestylesandattitudes(myown_data.data.lifestylesandattitudes);
    setSpokenlanguages(myown_data.data.spokenlanguages);
    setGetowndata(myown_data);
    setSports(myown_data.data.profilesports);
    setTraveling(myown_data.data.profiletravlingattitudes);
  };
  const getOwn = () => {
    setMyid(myown_data.data.profileid);
    setIspaid(myown_data.data.profileattributes.isapaidmember);

    if (myown_data.data.profileid === profileid) {
      history("/profile-settings/view");
    }
  };
  const getTopten = () => {
    fetchHandler(`${url}profile/gettopten`, "GET").then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        const data = result.data.map((ele) => ({
          id: ele.profileid,
          success: ele.hasaccessalreadybeenrequested,
        }));

        setTimeout(() => setMappedUsers(data), 0);
      } else {
        setSearchResult(null);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (reload === true) {
      getOwn();
      getProfileHobbies();
      getDataFromServer();
      getPublicData();
      getTopten();
      showFontPageData();
    }

    setReload(false);
  }, [language, changnow, reload]);

  useEffect(() => {
    setReload(true);
  }, [language]);

  const MakeEmpRow = ({ gdata, mydata }) => {
    const filterdata = mydata.filter(
      (item) => item.translistid === gdata.languageid
    );
    return (
      <div className="text-white px-2 py-1 rounded-md bg-sr text-xs font-medium">
        {filterdata && filterdata?.length !== 0 && filterdata[0].name}
      </div>
    );
  };
  const MakeEmpRow2 = ({ gdata, mydata }) => {
    const filterdata = mydata?.filter(
      (item) => item.translistid === gdata.hobbiesid
    );
    return (
      <div className="text-white px-2 py-1 rounded-md bg-sr text-xs font-medium">
        {filterdata && filterdata?.length !== 0 && filterdata[0]?.name}
      </div>
    );
  };
  const MakeSportRow = ({ gdata, mydata }) => {
    const filterdata = mydata.filter(
      (item) => item.translistid === gdata.sportattitudeid
    );
    return (
      <div className="text-white text-xs font-medium border px-2 py-1 text-center flex items-center justify-center bg-sr rounded-md">
        {filterdata && filterdata.length !== 0 && filterdata[0].name}
      </div>
    );
  };

  let pheaders = new Headers();
  pheaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  pheaders.append("Content-Type", "application/json");
  pheaders.append("Accept-Language", language || "en");

  const toastcontroller = (result) => {
    if (result.success) {
      toast.success(t("Toast_Successfully_Requesting_For_Profile_Photos"), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(result.errors === null ? result.message : result.errors[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };
  // requesting to a user for photo
  // const photoRequest = () => {
  //   let raw = JSON.stringify({
  //     profileId: profileid,
  //   });
  //   fetchHandler(`${url}image/requestaccess`, "POST", raw, pheaders).then(
  //     (response) => {
  //       if (response.success) {
  //         setReload(true);
  //       }
  //       toastcontroller(response);
  //     }
  //   );
  // };
  const photoRequest = useCallback(
    (userId = profileid) => {
      let raw = JSON.stringify({
        profileId: userId,
      });

      fetchHandler(`${url}image/requestaccess`, "POST", raw, pheaders).then(
        (result) => {
          if (result.success) {
            setReload(true);
            const updatedMappedItems = mappedUsers.map((item) => {
              if (item.id === userId) {
                return {
                  ...item,
                  success: true,
                };
              }
              return item;
            });

            // Set the state with the updated array
            setMappedUsers(updatedMappedItems);
          }
          toastcontroller(result);
        }
      );
    },
    [profileid, pheaders, reload]
  );
  const sendMessage = (e) => {
    e.preventDefault();
    setSendmessage(false);
    let raw = JSON.stringify({
      body: body,
      fromProfileId: myid,
      toProfileId: userid,
      messagetypeid: 1,
    });
    fetchHandler(`${url}inbox/newmessage`, "POST", raw, pheaders).then(
      (response) => {
        toastcontroller(response);
      }
    );
  };

  const [openpic, setOpenpic] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const [searchResult, setSearchResult] = useState([]);
  const [fontpageProfiles, setFontpageProfiles] = useState([]);
  const showFontPageData = () => {
    fetchHandler(`${url}profile/getfrontpageprofiles`, "GET").then((result) => {
      setFontpageProfiles(result.data);
    });
  };

  const renderOnlineTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("IsOnlineNow")}
    </Tooltip>
  );

  const renderOfflineTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("IsOffline")}
    </Tooltip>
  );

  const profileNotActiveOrNotApprovedHandler = () => {
    if (!isCurrentProfileApproved) {
      showSwal(
        t("AwaitingApproval_Header"),
        t("AwaitingApproval_Message"),
        "info"
      );
      return;
    }

    if (!isCurrentProfileAvailable) {
      showSwal(
        t("ProfileNotActive_Header"),
        t("ProfileNotActive_Message"),
        "info"
      );
      return;
    }
    if (isMarkedForDeletion) {
      showSwal(
        t("ProfileMarkedForDeletion_Header"),
        t("ProfileMarkedForDeletion_Message"),
        "info"
      );
    }
  };

  const showSwal = (title, message, type) => {
    Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
    });
  };

  return (
    <>
      {formdata && getowndata && username !== "" ? (
        <div className="container mt-20 lg:mt-16  lg:py-10 min-h-screen bg-blue-50 relative">
          <ToastContainer />

          <PeoplePhoto show={openpic} onHide={() => setOpenpic(false)} />
          <ProfilePhoto
            show={viewPhofilePhoto}
            onHide={() => setViewPhofilePhoto(false)}
          />

          {sendmessage && (
            <div
              className="fixed top-0 left-0 z-50"
              style={{
                height: "100%",
                width: "100%",
                background: "rgba(0,0,0,0.3)",
              }}
            >
              <div
                style={{ height: "500px" }}
                className="w-96  fixed bottom-2 px-2 border-2 border-pr overflow-hidden bg-white  shadow-md rounded-xl right-10 "
              >
                <div className="flex items-center justify-end pt-2 mb-2">
                  <AiFillCloseCircle
                    onClick={() => setSendmessage(false)}
                    className="text-yellow-500 w-6 h-6 cursor-pointer"
                  />
                </div>
                <div className="flex items-center justify-center flex-col mb-3">
                  <div className="h-16 w-16 rounded-full overflow-hidden mb-2">
                    {albumcontainer !== null ? (
                      <>
                        {albumcontainer.pictures.length !== 0 ? (
                          <img
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            src={`${process.env.REACT_APP_IMAGE}${profilepicture.path}`}
                            alt=""
                            onError={imageOnError}
                          />
                        ) : (
                          <img
                            src={imageSrc}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            onError={imageOnError}
                            alt=""
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={imageSrc}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        onError={imageOnError}
                        alt=""
                      />
                    )}
                  </div>
                  <h6 className="font-medium text-gray-700">{username}</h6>
                </div>
                <form onSubmit={(e) => sendMessage(e)}>
                  {ispaid ? (
                    <div>
                      <textarea
                        onChange={(e) => setBody(e.target.value)}
                        required
                        autoFocus
                        maxLength="4000"
                        cols="25"
                        rows="10"
                        placeholder={t("Write_Your_Message_Here")}
                        className="outline-none border p-3 w-full h-72 rounded-md"
                      ></textarea>
                    </div>
                  ) : (
                    <div className="h-72 mb-8 px-2 rounded-md bg-gray-200 flex items-center justify-center">
                      <p className="text-red-500 flex  gap-2">
                        <AiOutlineWarning className="h-6 w-6" />
                        {t("Only_Paid_Member_Can_Send_Message")}
                      </p>
                    </div>
                  )}

                  {ispaid && (
                    <div className="w-full flex items-center justify-between">
                      <p className=" font-medium text-green-600">
                        {body.length} / 4000
                      </p>
                      <button
                        type="submit"
                        className="bg-pr hover:bg-sr px-2 py-2 text-white rounded-sm"
                      >
                        {t("Send")}
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          )}
          <div className="container">
            <ProfileNavbar />
          </div>
          {scrollPosition > 540 && (
            <div
              style={{ zIndex: "200" }}
              className="fixed top-20 left-0 h-28 w-full bg-pr shadow-md flex items-center justify-center lg:px-16"
            >
              <div className="w-20 h-20 z-50  relative rounded-full p-2 mt-3 bg-sr shadow-md">
                <div className="w-full h-full rounded-full overflow-hidden">
                  {albumcontainer !== null ? (
                    albumcontainer?.pictures.length !== 0 ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        className="rounded-full cursor-pointer"
                        onClick={() => setViewPhofilePhoto(true)}
                        src={`${process.env.REACT_APP_IMAGE}${profilepicture?.path}`}
                        onError={imageOnError}
                        alt=""
                      />
                    ) : (
                      <>
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          className="rounded-full cursor-pointer"
                          onError={imageOnError}
                          src={imageSrc}
                          alt=""
                        />
                      </>
                    )
                  ) : (
                    <>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        className="rounded-full cursor-pointer"
                        src={imageSrc}
                        onError={imageOnError}
                        alt=""
                      />
                    </>
                  )}
                </div>
                <div className="text-white absolute top-0 right-3 h-6 w-6">
                  {profileattributes.subscriptiontype === "Platinum" && (
                    <img
                      src={platinum}
                      onError={(event) => (event.target.style.display = "none")}
                      alt={t("Platinum")}
                    />
                  )}
                  {profileattributes.subscriptiontype === "Gold" && (
                    <img
                      src={gold}
                      onError={(event) => (event.target.style.display = "none")}
                      alt={t("Gold")}
                    />
                  )}
                  {profileattributes.subscriptiontype === "Basic" && (
                    <img
                      src={basic}
                      onError={(event) => (event.target.style.display = "none")}
                      alt={t("Basic")}
                    />
                  )}
                </div>
              </div>
              <div className="flex mt-3 items-center justify-center h-20">
                <h1 className="font-md text-2xl  flex items-center flex-wrap">
                  {profileattributes.online ? (
                    <div className="w-5 h-5 bg-green-500 ml-2  rounded-full "></div>
                  ) : (
                    <div className="w-5 h-5 bg-gray-400 ml-2  rounded-full "></div>
                  )}
                  <span
                    className="text-3xl font-medium text-sr ml-2"
                    style={{ direction: "ltr" }}
                  >
                    {username}
                  </span>
                </h1>
              </div>
            </div>
          )}

          <div className="container">
            <div className="w-full">
              <div className="w-full relative h-96">
                <div className="w-full h-96 overflow-hidden">
                  {albumcontainer !== null ? (
                    <>
                      {albumcontainer?.pictures.length !== 0 ? (
                        <>
                          {ppublic ? (
                            <>
                              {profilepicture?.approved ? (
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                  src={`${process.env.REACT_APP_IMAGE}${profilepicture.path}`}
                                  onError={imageOnError}
                                  alt=""
                                />
                              ) : (
                                <>
                                  <img
                                    src={cover}
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                    onError={(event) =>
                                      (event.target.style.display = "none")
                                    }
                                    alt=""
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div>
                                {profilepicture.approved ? (
                                  <img
                                    alt=""
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                    onError={(event) =>
                                      (event.target.style.display = "none")
                                    }
                                    src={cover}
                                  />
                                ) : (
                                  <>
                                    <img
                                      src={cover}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                      }}
                                      onError={(event) =>
                                        (event.target.style.display = "none")
                                      }
                                      alt=""
                                    />
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <img
                            src={cover}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            onError={(event) =>
                              (event.target.style.display = "none")
                            }
                            alt=""
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <img
                      src={cover}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      onError={(event) => (event.target.style.display = "none")}
                      alt=""
                    />
                  )}
                </div>
                <div
                  className=" absolute top-0 left-0 z-40 flex justify-center items-start pt-20 "
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#032e42e5",
                  }}
                >
                  <p
                    style={{ width: "60%" }}
                    className=" text-white font-medium text-xs md:text-xl text-center"
                  >
                    {title !== "" && title}
                  </p>
                </div>

                <div className="mb-7 flex items-center flex-col md:flex-row justify-center gap-2 text-yellow-500 px-3 py-2 z-40  p-2 absolute  left-0 lg:left-6 bottom-5">
                  <div>
                    <p className="bg-sr text-yellow-500 rounded-md border-2 text-sm md:text-base border-pr font-medium px-4 py-1 ">
                      {t("Age")}
                    </p>
                    <p className="text-yellow-500 md:text-2xl font-medium px-4 text-sm py-1 ">
                      {profileattributes.age}
                    </p>
                  </div>
                  {countryoriginCode && (
                    <div className="flex items-center gap-0 flex-col">
                      <p className=" px-2 lg:px-4 py-1 ">
                        <Flag
                          code={countryoriginCode}
                          className="rounded h-6 md:h-8 lg:h-16"
                        />
                      </p>
                    </div>
                  )}
                </div>

                <div className="mb-3 flex items-center flex-col md:flex-row justify-center gap-2 text-yellow-500 px-3 py-2 z-40  p-2 absolute  right-0 lg:right-6 bottom-5">
                  <div>
                    <p className="bg-sr text-yellow-500 rounded-md border-2 text-sm md:text-base border-pr font-medium px-4 py-1  text-center">
                      {t("Seeking")}
                    </p>
                    <p className="md:text-2xl text-sm font-medium px-4 py-1  flex gap-2 text-yellow-500">
                      {profileattributes.seekingminage}-
                      {profileattributes.seekingmaxage}
                    </p>
                  </div>
                </div>

                <div className="md:w-52 md:h-52 w-32 h-32 z-50  relative rounded-full p-2 profile_container bg-pr shadow-md">
                  <div className="w-full h-full rounded-full overflow-hidden">
                    {albumcontainer !== null ? (
                      albumcontainer?.pictures.length !== 0 ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          onClick={() => setViewPhofilePhoto(true)}
                          className="rounded-full cursor-pointer"
                          src={`${process.env.REACT_APP_IMAGE}${profilepicture?.path}`}
                          onError={imageOnError}
                          alt=""
                        />
                      ) : (
                        <>
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            className="rounded-full cursor-pointer"
                            src={imageSrc}
                            onError={imageOnError}
                            alt=""
                          />
                        </>
                      )
                    ) : (
                      <>
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          className="rounded-full cursor-pointer"
                          onError={imageOnError}
                          src={imageSrc}
                          alt=""
                        />
                      </>
                    )}
                  </div>
                  <div className="text-white absolute top-0 right-3 h-16 w-16">
                    {profileattributes.subscriptiontype === "Platinum" && (
                      <img
                        src={platinum}
                        alt={t("Platinum")}
                        onError={(event) =>
                          (event.target.style.display = "none")
                        }
                      />
                    )}
                    {profileattributes.subscriptiontype === "Gold" && (
                      <img
                        src={gold}
                        alt={t("Gold")}
                        onError={(event) =>
                          (event.target.style.display = "none")
                        }
                      />
                    )}
                    {profileattributes.subscriptiontype === "Basic" && (
                      <img
                        src={basic}
                        alt={t("Basic")}
                        onError={(event) =>
                          (event.target.style.display = "none")
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-center flex-col mt-24 gap-2">
                <div className="flex items-center gap-1 pt-1">
                  {profileattributes.online ? (
                    <div className="w-5 h-5 bg-green-500 ml-2  rounded-full "></div>
                  ) : (
                    <div className="w-5 h-5 bg-gray-400 ml-2  rounded-full "></div>
                  )}
                  {profileattributes.online ? (
                    <p className="text-green-500 text-xl font-bold animate-pulse">
                      {t("Online")}
                    </p>
                  ) : (
                    <p className="text-gray-500 text-xl font-bold">
                      {t("Offline")}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-center">
                  <h1 className="font-md text-2xl  flex items-center flex-wrap ">
                    <span
                      className="text-3xl font-medium text-sr ml-2 flex"
                      style={{ direction: "ltr" }}
                    >
                      {username}
                      <Link
                        to="/profile-settings/edit"
                        className=" text-gray-700 py-2 hover:text-pr ml-5"
                      >
                        <FaUserEdit
                          className="h-5 w-5 mr-1"
                          title={t("Profile_Edit")}
                        />
                      </Link>
                    </span>
                  </h1>
                </div>
              </div>

              <div className="grid grid-cols-1 mt-10 lg:grid-cols-7 lg:px-20 gap-3">
                <div className=" col-span-1 lg:col-span-3">
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6">
                    <div className=" py-2 flex items-center gap-2 text-xl font-bold">
                      <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                        <GoInfo className="w-5 h-5  text-white" />
                      </div>{" "}
                      {t("Profile_View_Introdution")}
                    </div>
                    <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                      <p
                        className={`flex items-center font-medium my-1 flex-wrap mt-2  gap-2 " ${
                          location !== "" ? "text-gray-600" : "text-gray-300"
                        }`}
                      >
                        <MdLocationOn className="mr-4 text-yellow-500 " />
                        {location !== "" ? location : `${t("No_Data")}`}
                      </p>
                      <p className="text-gray-600 flex items-center font-medium my-1 flex-nowrap gap-2">
                        <RiGenderlessLine className="mr-4 text-yellow-500" />
                        {gender ? `${t("Male")}` : `${t("Female")}`}
                      </p>
                      <p className="text-gray-600 flex items-center font-medium my-1 flex-wrap gap-2 ">
                        <FaIdCard className="mr-4 text-yellow-500  " />
                        <span ref={textRef}> {userid}</span>
                        {valueCopied ? (
                          <FaCopy
                            onClick={handleCopyClick}
                            className=" text-gray-500 hover:text-pr cursor-pointer"
                          />
                        ) : (
                          <FaRegCopy
                            onClick={handleCopyClick}
                            className="text-gray-500 hover:text-pr cursor-pointer"
                            title="Copy text to clipboard"
                          />
                        )}
                      </p>

                      <p
                        className={`flex items-center font-medium my-1 flex-wrap mt-2  gap-2 " ${
                          profileattributes.lastactivitydatetime !== null
                            ? "text-gray-600"
                            : "text-gray-300"
                        }`}
                      >
                        <FiActivity className="mr-4 text-yellow-500" />
                        {t("Profile_View_LastActive")}
                        {profileattributes.lastactivitydatetime !== null
                          ? profileattributes.lastactivitydatetime.slice(0, 10)
                          : `${t("No_Data")}`}
                      </p>
                    </div>
                  </div>
                  <>
                    {albumcontainer !== null && (
                      <>
                        {albumcontainer.pictures?.length !== 0 && (
                          <>
                            {thumbnileimages.length !== 0 && (
                              <div className=" w-full p-2 bg-white rounded-md shadow-md">
                                <div className="py-2 flex items-center gap-2 text-xl font-bold">
                                  <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                                    <IoImageOutline className="w-5 h-5  text-white" />
                                  </div>{" "}
                                  {t("Profile_Photos")}
                                </div>
                                <div className="grid grid-cols-2 w-full gap-1">
                                  {thumbnileimages?.map((item, ind) => (
                                    <div key={ind} className="cursor-pointer">
                                      <img
                                        onClick={() => {
                                          photomix(item);
                                          setOpenpic(true);
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                        alt=""
                                        src={`${process.env.REACT_APP_IMAGE}${item.path}`}
                                        onError={(event) =>
                                          (event.target.style.display = "none")
                                        }
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                  <div className=" w-full p-2 mt-3 bg-white rounded-md shadow-md mb-6">
                    <div className=" py-2 flex items-center gap-2 text-xl font-bold">
                      <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                        <SiInformatica className="w-5 h-5  text-white" />
                      </div>{" "}
                      {t("Profile_View_About_Me")}
                    </div>
                    <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                      <p
                        className={`flex items-center font-medium my-1 mt-2 " ${
                          aboutme !== "" ? "text-gray-600" : "text-gray-300"
                        }`}
                      >
                        {aboutme !== "" ? aboutme : t("No_Data")}
                      </p>
                    </div>
                  </div>
                  <div className=" w-full p-2 mt-3 bg-white rounded-md shadow-md mb-6">
                    <div className=" py-2 flex items-center gap-2 text-xl font-bold">
                      <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                        <SiLooker className="w-5 h-5  text-white" />
                      </div>{" "}
                      {t("Profile_View_Looking_For")}
                    </div>
                    <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                      <p
                        className={`flex items-center font-medium my-1 mt-2 " ${
                          looking !== "" ? "text-gray-600" : "text-gray-300"
                        }`}
                      >
                        {looking !== "" ? looking : t("No_Data")}
                      </p>
                    </div>
                  </div>

                  <div className="hidden sm:block">
                    {fontpageProfiles.length !== 0 && (
                      <div className=" w-full p-2 bg-sr rounded-md shadow-md mt-3">
                        <div className=" py-2 flex items-center gap-2 text-white text-xl font-bold">
                          <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                            <MdOutlineRecommend className="w-5 h-5  text-white" />
                          </div>{" "}
                          {t("Recommended_Profiles")}
                        </div>
                        {/* <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                          <Carousel>
                            {fontpageProfiles.map((data, ind) => (
                              <Carousel.Item key={ind}>
                                <div className="p-5 ">
                                  <Card
                                    className="shadow-md"
                                    //   style={{ height: "480px" }}
                                  >
                                    {data.online ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderOnlineTooltip}
                                      >
                                        <div
                                          className={
                                            "w-5 h-5 bg-green-500 rounded-full absolute z-20 top-0 " +
                                            (language === "ar"
                                              ? "left-0"
                                              : "right-0")
                                          }
                                        ></div>
                                      </OverlayTrigger>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderOfflineTooltip}
                                      >
                                        <div
                                          className={
                                            "w-5 h-5 bg-gray-500 rounded-full absolute z-20 top-0 " +
                                            (language === "ar"
                                              ? "left-0"
                                              : "right-0")
                                          }
                                        ></div>
                                      </OverlayTrigger>
                                    )}
                                    {data.isnew && (
                                      <div className=" bg-green-500 text-white rounded p-1 absolute z-10 top-0 text-xs">
                                        {t("New")}
                                      </div>
                                    )}

                                    <Link
                                      style={{
                                        //  heigth: "300px",
                                        overflow: "hidden",
                                        position: "relative",
                                      }}
                                      to={"/profile/" + data.profileid}
                                    >
                                      {data.url !== "" ? (
                                        <img
                                          style={
                                            data.ispicturesprivate === true &&
                                            data.hascurrentuseraccesstoviewcardimages ===
                                              false
                                              ? {
                                                  //  height: "300px",
                                                  width: "100%",
                                                  objectFit: "contain",
                                                  filter: "blur(10px)",
                                                }
                                              : {
                                                  //     height: "300px",
                                                  width: "100%",
                                                  objectFit: "contain",
                                                }
                                          }
                                          src={`${process.env.REACT_APP_IMAGE}images/${data.url}`}
                                          onError={imageOnError}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src={imageSrc}
                                          style={{
                                            //  height: "300px",
                                            width: "100%",
                                            objectFit: "contain",
                                          }}
                                          onError={(event) =>
                                            (event.target.style.display =
                                              "none")
                                          }
                                          alt=""
                                        />
                                      )}
                                      {data.ispicturesprivate === true &&
                                        data.hascurrentuseraccesstoviewcardimages ===
                                          false &&
                                        data?.totalimages > 0 && (
                                          <div
                                            onClick={
                                              data.hasaccessalreadybeenrequested
                                                ? null
                                                : isCurrentProfileApproved &&
                                                  isCurrentProfileAvailable &&
                                                  !isMarkedForDeletion
                                                ? photoRequest
                                                : profileNotActiveOrNotApprovedHandler
                                            }
                                            style={{
                                              zIndex: "6000",
                                              position: "absolute",
                                              left: "0%",
                                              right: "0%",
                                              top: "88%",
                                              // transform: "translateY(-50%)",
                                              // backgroundColor: "lightblue",
                                              textAlign: "center",
                                            }}
                                            className={
                                              data.hasaccessalreadybeenrequested
                                                ? " px-2 py-2 absolute bottom-15 bg-gray-500 text-white text-sm rounded-lg "
                                                : "px-2 py-2 absolute bottom-15 hover:bg-sr cursor-pointer  text-white text-sm rounded-lg bg-pr"
                                            }
                                            // className={
                                            //   data.hasaccessalreadybeenrequested
                                            //     ? "px-2 py-1 absolute bottom-8 bg-gray-500 z-40 left-7 text-white text-sm rounded-md"
                                            //     : "px-2 py-1 absolute bottom-8 hover:bg-sr cursor-pointer z-40 left-7 text-white text-sm rounded-md bg-pr"
                                            // }
                                          >
                                            {data.hasaccessalreadybeenrequested
                                              ? t("Request_Already_Sent")
                                              : t("Ask_About_My_Photo")}
                                          </div>
                                        )}
                                    </Link>

                                    <Card.Body>
                                      <div className="h-40 overflow-hidden">
                                        <h1 className="text-xl pb-2 text-center font-bold text-gray-600">
                                          <Link
                                            to={"/profile/" + data.profileid}
                                            style={{ direction: "ltr" }}
                                          >
                                            {data.displayusername}
                                          </Link>
                                        </h1>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.age !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          <span className="font-medium">
                                            {t("Age")} :
                                          </span>{" "}
                                          {data.age !== ""
                                            ? data.age
                                            : `${t("No_Data")}`}
                                        </p>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.cityname !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          <span className="font-medium">
                                            {t("Location")} :
                                          </span>{" "}
                                          {data.cityname !== "" &&
                                          data.cityname !== null
                                            ? data.cityname
                                            : `${t("No_Data")}`}
                                        </p>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.country !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          {" "}
                                          <span className="font-medium">
                                            {t("CountryOfResidence")}:{" "}
                                          </span>{" "}
                                          {data.country !== "" &&
                                          data.country !== null
                                            ? data.country
                                            : `${t("No_Data")}`}
                                        </p>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.seekinggender
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          <span className="font-medium">
                                            {t("Seeking")} :
                                          </span>{" "}
                                          {data.seekinggender
                                            ? t("Male")
                                            : t("Female")}{" "}
                                          {data.seekingminage}-
                                          {data.seekingmaxage}
                                        </p>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        </div> */}
                        <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                          <Carousel>
                            {fontpageProfiles.map((data, ind) => (
                              <Carousel.Item key={ind}>
                                <div className="p-5">
                                  <Card
                                    className="shadow-md"
                                    //   style={{ height: "480px" }}
                                  >
                                    {data.online ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderOnlineTooltip}
                                      >
                                        <div
                                          className={
                                            "w-5 h-5 bg-green-500 rounded-full absolute z-20 top-0 " +
                                            (language === "ar"
                                              ? "left-0"
                                              : "right-0")
                                          }
                                        ></div>
                                      </OverlayTrigger>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderOfflineTooltip}
                                      >
                                        <div
                                          className={
                                            "w-5 h-5 bg-gray-500 rounded-full absolute z-20 top-0 " +
                                            (language === "ar"
                                              ? "left-0"
                                              : "right-0")
                                          }
                                        ></div>
                                      </OverlayTrigger>
                                    )}
                                    {data.isnew && (
                                      <div className=" bg-green-500 text-white rounded p-1 absolute z-10 top-0 right-left text-xs">
                                        {t("New")}
                                      </div>
                                    )}

                                    <Link
                                      onClick={() => {
                                        setUsername("");
                                        setReload(true);
                                      }}
                                      to={"/profile/" + data.profileid}
                                      style={{
                                        // heigth: "100px",
                                        //    heigth: "300px",
                                        overflow: "hidden",
                                        position: "relative",
                                        width: "100%",
                                      }}
                                    >
                                      <div class="flex justify-center items-center relative">
                                        {data.url !== "" ? (
                                          <img
                                            style={
                                              data.ispicturesprivate === true &&
                                              data.hascurrentuseraccesstoviewcardimages ===
                                                false
                                                ? {
                                                    //     height: "300px",
                                                    width: "100%",
                                                    objectFit: "contain",
                                                  }
                                                : {
                                                    //     height: "300px",
                                                    width: "100%",
                                                    objectFit: "contain",
                                                  }
                                            }
                                            src={`${process.env.REACT_APP_IMAGE}${data.url}`}
                                            alt=""
                                            onError={imageOnError}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={imageSrc}
                                            style={{
                                              height: "200px",
                                              width: "100%",
                                              objectFit: "contain",
                                            }}
                                            alt=""
                                            onError={imageOnError}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        )}
                                        <div
                                          className="absolute bottom-1 right-1  overflow-hidden z-20000 rounded-md "
                                          style={{
                                            zIndex: "10",
                                          }}
                                        >
                                          <Flag
                                            code={data?.countryoforigincode}
                                            className="h-6"
                                            title={data?.countryoforigin}
                                          />
                                        </div>
                                      </div>
                                      {data.ispicturesprivate === true &&
                                        data.hascurrentuseraccesstoviewcardimages ===
                                          false &&
                                        data?.totalimages > 0 && (
                                          <div
                                            style={{
                                              zIndex: "6000",
                                              position: "absolute",
                                              left: "0%",
                                              right: "0%",
                                              top: "85%",
                                              // transform: "translateY(-50%)",
                                              // backgroundColor: "lightblue",
                                              textAlign: "center",
                                            }}
                                            onClick={
                                              data.hasaccessalreadybeenrequested
                                                ? null
                                                : isCurrentProfileApproved &&
                                                  isCurrentProfileAvailable &&
                                                  !isMarkedForDeletion
                                                ? photoRequest
                                                : profileNotActiveOrNotApprovedHandler
                                            }
                                            className={
                                              data.hasaccessalreadybeenrequested
                                                ? " px-2 py-2 absolute bottom-15 bg-gray-500 text-white text-sm rounded-lg "
                                                : "px-2 py-2 absolute bottom-15 hover:bg-sr cursor-pointer  text-white text-sm rounded-lg bg-pr"
                                            }
                                          >
                                            {data.hasaccessalreadybeenrequested
                                              ? t("Request_Already_Sent")
                                              : t("Ask_About_My_Photo")}
                                          </div>
                                        )}
                                    </Link>

                                    <Card.Body>
                                      <div className="h-28 overflow-hidden">
                                        <h1
                                          className="text-xl pb-2 text-center font-bold text-gray-600"
                                          style={{ direction: "ltr" }}
                                        >
                                          <Link
                                            style={{ direction: "ltr" }}
                                            onClick={() => {
                                              setUsername("");
                                              setReload(true);
                                            }}
                                            to={"/profile/" + data.profileid}
                                          >
                                            {data.displayusername}
                                          </Link>
                                        </h1>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.age !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          <span className="font-medium">
                                            {t("Age")} :
                                          </span>{" "}
                                          {data.age !== ""
                                            ? data.age
                                            : `${t("No_Data")}`}
                                        </p>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.cityname !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          <span className="font-medium">
                                            {t("Location")} :
                                          </span>{" "}
                                          {data.cityname !== "" &&
                                          data.cityname !== null
                                            ? data.cityname
                                            : `${t("No_Data")}`}
                                        </p>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.country !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          {" "}
                                          <span className="font-medium">
                                            {t("CountryOfResidence")}:{" "}
                                          </span>{" "}
                                          {data.country !== "" &&
                                          data.country !== null
                                            ? data.country
                                            : `${t("No_Data")}`}
                                        </p>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.seekinggender !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          <span className="font-medium">
                                            {t("Seeking")} :
                                          </span>{" "}
                                          {data.seekinggender
                                            ? t("Male")
                                            : t("Female")}{" "}
                                          {data.seekingminage}-
                                          {data.seekingmaxage}
                                        </p>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        </div>
                      </div>
                    )}
                    {searchResult?.length !== 0 &&
                      searchResult?.length !== undefined && (
                        <div className=" w-full p-2 bg-sr rounded-md shadow-md mt-3">
                          <div className=" py-2 flex items-center gap-2 text-white text-xl font-bold capitalize">
                            <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                              <BsTrophyFill className="w-5 h-5  text-white" />
                            </div>{" "}
                            {t("Top_Ten_Profiles")}
                          </div>
                          {/* <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                            <Carousel>
                              {searchResult?.map((data, ind) => (
                                <Carousel.Item key={ind}>
                                  <div className="p-5">
                                    <Card
                                      className="shadow-md"
                                      //    style={{ height: "480px" }}
                                    >
                                      {data.online ? (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderOnlineTooltip}
                                        >
                                          <div
                                            className={
                                              "w-5 h-5 bg-green-500 rounded-full absolute z-20 top-0 " +
                                              (language === "ar"
                                                ? "left-0"
                                                : "right-0")
                                            }
                                          ></div>
                                        </OverlayTrigger>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderOfflineTooltip}
                                        >
                                          <div
                                            className={
                                              "w-5 h-5 bg-gray-500 rounded-full absolute z-20 top-0 " +
                                              (language === "ar"
                                                ? "left-0"
                                                : "right-0")
                                            }
                                          ></div>
                                        </OverlayTrigger>
                                      )}
                                      {data.isnew && (
                                        <div className=" bg-green-500 text-white rounded p-1 absolute z-10 top-0 text-xs">
                                          {t("New")}
                                        </div>
                                      )}

                                      <Link
                                        onClick={() => {
                                          setUsername("");
                                          setReload(true);
                                        }}
                                        style={{
                                          //     heigth: "300px",
                                          overflow: "hidden",
                                          position: "relative",
                                          width: "100%",
                                        }}
                                        to={"/profile/" + data.profileid}
                                      >
                                        {data.url !== "" ? (
                                          <img
                                            style={
                                              data.ispicturesprivate === true &&
                                              data.hascurrentuseraccesstoviewcardimages ===
                                                false
                                                ? {
                                                    //    height: "300px",
                                                    width: "100%",
                                                    //    objectFit: "contain",
                                                  }
                                                : {
                                                    //   height: "300px",
                                                    width: "100%",
                                                    //objectFit: "contain",
                                                  }
                                            }
                                            src={`${process.env.REACT_APP_IMAGE}images/${data.url}`}
                                            onError={imageOnError}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src={imageSrc}
                                            style={{
                                              height: "200px",
                                              width: "100%",
                                              objectFit: "contain",
                                            }}
                                            // onError={(event) =>
                                            //   (event.target.style.display =
                                            //     "none")
                                            // }
                                            // alt=""
                                            alt=""
                                            onError={imageOnError}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        )}

                                        {data.ispicturesprivate === true &&
                                          data.hascurrentuseraccesstoviewcardimages ===
                                            false &&
                                          data?.totalimages > 0 && (
                                            <div
                                              onClick={
                                                data.hasaccessalreadybeenrequested
                                                  ? null
                                                  : isCurrentProfileApproved &&
                                                    isCurrentProfileAvailable &&
                                                    !isMarkedForDeletion
                                                  ? photoRequest
                                                  : profileNotActiveOrNotApprovedHandler
                                              }
                                              style={{
                                                zIndex: "6000",
                                                position: "absolute",
                                                left: "0%",
                                                right: "0%",
                                                top: "88%",
                                                // transform: "translateY(-50%)",
                                                // backgroundColor: "lightblue",
                                                textAlign: "center",
                                              }}
                                              className={
                                                data.hasaccessalreadybeenrequested
                                                  ? " px-2 py-2 absolute bottom-15 bg-gray-500 text-white text-sm rounded-lg "
                                                  : "px-2 py-2 absolute bottom-15 hover:bg-sr cursor-pointer  text-white text-sm rounded-lg bg-pr"
                                              }
                                              // className={
                                              //   data.hasaccessalreadybeenrequested
                                              //     ? "px-2 py-1 absolute bottom-8 bg-gray-500 z-40 left-32 text-white text-sm rounded-md"
                                              //     : "px-2 py-1 absolute bottom-8 hover:bg-sr cursor-pointer z-40 left-32 text-white text-sm rounded-md bg-pr"
                                              // }
                                            >
                                              {data.hasaccessalreadybeenrequested
                                                ? t("Request_Already_Sent")
                                                : t("Ask_About_My_Photo")}
                                            </div>
                                          )}
                                      </Link>

                                      <Card.Body>
                                        <div className="h-40 overflow-hidden">
                                          <h1 className="text-xl pb-2 text-center font-bold text-gray-600">
                                            <Link
                                              style={{ direction: "ltr" }}
                                              onClick={() => {
                                                setUsername("");
                                                setReload(true);
                                              }}
                                              to={"/profile/" + data.profileid}
                                            >
                                              {data.displayusername}
                                            </Link>
                                          </h1>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.age !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            <span className="font-medium">
                                              {t("Age")} :
                                            </span>{" "}
                                            {data.age !== ""
                                              ? data.age
                                              : `${t("No_Data")}`}
                                          </p>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.cityname !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            <span className="font-medium">
                                              {t("Location")} :
                                            </span>{" "}
                                            {data.cityname !== "" &&
                                            data.cityname !== null
                                              ? data.cityname
                                              : `${t("No_Data")}`}
                                          </p>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.country !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            {" "}
                                            <span className="font-medium">
                                              {t("CountryOfResidence")}:{" "}
                                            </span>{" "}
                                            {data.country !== "" &&
                                            data.country !== null
                                              ? data.country
                                              : `${t("No_Data")}`}
                                          </p>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.seekinggender
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            <span className="font-medium">
                                              {t("Seeking")} :
                                            </span>{" "}
                                            {data.seekinggender
                                              ? t("Male")
                                              : t("Female")}{" "}
                                            {data.seekingminage}-
                                            {data.seekingmaxage}
                                          </p>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          </div> */}
                          <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                            <Carousel>
                              {searchResult?.map((data, ind) => (
                                <Carousel.Item key={ind}>
                                  <div className="p-5">
                                    <Card
                                      className="shadow-md"
                                      //    style={{ height: "480px" }}
                                    >
                                      {data.online ? (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderOnlineTooltip}
                                        >
                                          <div
                                            className={
                                              "w-5 h-5 bg-green-500 rounded-full absolute z-20 top-0 " +
                                              (language === "ar"
                                                ? "left-0"
                                                : "right-0")
                                            }
                                          ></div>
                                        </OverlayTrigger>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderOfflineTooltip}
                                        >
                                          <div
                                            className={
                                              "w-5 h-5 bg-gray-500 rounded-full absolute z-20 top-0 " +
                                              (language === "ar"
                                                ? "left-0"
                                                : "right-0")
                                            }
                                          ></div>
                                        </OverlayTrigger>
                                      )}
                                      {data.isnew && (
                                        <div className=" bg-green-500 text-white rounded p-1 absolute z-10 top-0 text-xs">
                                          {t("New")}
                                        </div>
                                      )}

                                      <Link
                                        onClick={() => {
                                          setUsername("");
                                          setReload(true);
                                        }}
                                        to={"/profile/" + data.profileid}
                                        style={{
                                          //  heigth: "300px",
                                          overflow: "hidden",
                                          position: "relative",
                                          width: "100%",
                                        }}
                                      >
                                        <div class="flex justify-center items-center relative">
                                          {data.url !== "" ? (
                                            <img
                                              style={
                                                data.ispicturesprivate ===
                                                  true &&
                                                data.hascurrentuseraccesstoviewcardimages ===
                                                  false
                                                  ? {
                                                      //     height: "300px",
                                                      width: "100%",
                                                      //     objectFit: "cover",
                                                    }
                                                  : {
                                                      //    height: "300px",
                                                      width: "100%",
                                                      //   objectFit: "cover",
                                                    }
                                              }
                                              src={`${process.env.REACT_APP_IMAGE}${data.url}`}
                                              alt=""
                                              onError={imageOnError}
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                            />
                                          ) : (
                                            <img
                                              src={imageSrc}
                                              style={{
                                                height: "200px",
                                                width: "100%",
                                                objectFit: "contain",
                                              }}
                                              alt=""
                                              onError={imageOnError}
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                            />
                                          )}
                                          <div
                                            className="absolute bottom-1 right-1  overflow-hidden z-20000 rounded-md "
                                            style={{
                                              zIndex: "10",
                                            }}
                                          >
                                            <Flag
                                              code={data?.countryoforigincode}
                                              className="h-6"
                                              title={data?.countryoforigin}
                                            />
                                          </div>
                                        </div>
                                      </Link>
                                      {data.ispicturesprivate === true &&
                                        data.hascurrentuseraccesstoviewcardimages ===
                                          false &&
                                        mappedUsers.length > 0 &&
                                        data?.totalimages > 0 && (
                                          <div
                                            onClick={() => {
                                              if (
                                                !data.hasaccessalreadybeenrequested &&
                                                !mappedUsers[ind].success
                                              ) {
                                                if (
                                                  isCurrentProfileApproved &&
                                                  isCurrentProfileAvailable &&
                                                  !isMarkedForDeletion
                                                ) {
                                                  photoRequest(data.profileid);
                                                } else {
                                                  profileNotActiveOrNotApprovedHandler();
                                                }
                                              }
                                            }}
                                            style={{
                                              textAlign: "center",
                                            }}
                                            className={
                                              data.hasaccessalreadybeenrequested ||
                                              mappedUsers[ind].success
                                                ? "px-2 py-2 bg-gray-500 text-white text-sm"
                                                : "px-2 py-2 hover:bg-sr cursor-pointer text-white text-sm bg-pr"
                                            }
                                          >
                                            {data.hasaccessalreadybeenrequested ||
                                            mappedUsers[ind].success
                                              ? t("Request_Already_Sent")
                                              : t("Ask_About_My_Photo")}
                                          </div>
                                        )}

                                      <Card.Body>
                                        <div className="h-28 overflow-hidden">
                                          <h1
                                            className="text-xl pb-2 text-center font-bold text-gray-600"
                                            style={{ direction: "ltr" }}
                                          >
                                            <Link
                                              style={{ direction: "ltr" }}
                                              onClick={() => {
                                                setUsername("");
                                                setReload(true);
                                              }}
                                              to={"/profile/" + data.profileid}
                                            >
                                              {data.displayusername}
                                            </Link>
                                          </h1>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.age !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            <span className="font-medium">
                                              {t("Age")} :
                                            </span>{" "}
                                            {data.age !== ""
                                              ? data.age
                                              : `${t("No_Data")}`}
                                          </p>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.cityname !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            <span className="font-medium">
                                              {t("Location")} :
                                            </span>{" "}
                                            {data.cityname !== "" &&
                                            data.cityname !== null
                                              ? data.cityname
                                              : `${t("No_Data")}`}
                                          </p>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.country !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            {" "}
                                            <span className="font-medium">
                                              {t("CountryOfResidence")}:{" "}
                                            </span>{" "}
                                            {data.country !== "" &&
                                            data.country !== null
                                              ? data.country
                                              : `${t("No_Data")}`}
                                          </p>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.seekinggender !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            <span className="font-medium">
                                              {t("Seeking")} :
                                            </span>{" "}
                                            {data.seekinggender
                                              ? t("Male")
                                              : t("Female")}{" "}
                                            {data.seekingminage}-
                                            {data.seekingmaxage}
                                          </p>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className=" w-full lg:col-span-4 h-full">
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className="w-full">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <FaStreetView className="w-5 h-5  text-white" />
                        </div>
                        {t("Overview")}
                      </div>
                      {bodyshapedescription !== null && (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Height")}
                              attribute={bodyshapedescription.bodyheightid}
                              mydata={formdata.bodyheight}
                              description={t("cm")}
                            />
                            <MakeRow
                              title={t("Hair_Color")}
                              attribute={bodyshapedescription.haircolorid}
                              mydata={formdata.haircolor}
                            />
                            <MakeRow
                              title={t("Hair_Type")}
                              attribute={bodyshapedescription.hairtypeid}
                              mydata={formdata.hairtype}
                            />
                          </tbody>
                        </Table>
                      )}

                      {lifestylesandattitudes !== null && (
                        <Table bordered style={{ marginTop: "-16px" }}>
                          <tbody>
                            <MakeRow
                              title={t("Children_Have_Attitude")}
                              attribute={
                                lifestylesandattitudes.childrenhaveattitudeid
                              }
                              mydata={formdata.childrenhaveattitude}
                            />
                            <MakeRow
                              title={t("Smoke_Attitude")}
                              attribute={lifestylesandattitudes.smokeattitudeid}
                              mydata={formdata.smokeattitude}
                            />
                            <MakeRow
                              title={t("Children_Attitude")}
                              attribute={
                                lifestylesandattitudes.childrenattitudeid
                              }
                              mydata={formdata.childrenattitude}
                            />
                          </tbody>
                        </Table>
                      )}

                      <Table bordered style={{ marginTop: "-16px" }}>
                        <tbody>
                          <MakeRow
                            title={t("CountryOfResidence")}
                            attribute={country}
                            mydata={formdata.country}
                          />
                          <MakeRow
                            title={t("Country_Of_Origin")}
                            attribute={countryorigin}
                            mydata={formdata.country}
                          />
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className="w-full">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <BsPersonBoundingBox className="w-5 h-5  text-white" />
                        </div>
                        {t("Appearance")}
                      </div>
                      {bodyshapedescription !== null ? (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Height")}
                              attribute={bodyshapedescription.bodyheightid}
                              mydata={formdata.bodyheight}
                              description={t("cm")}
                            />

                            <MakeRow
                              title={t("Body_Look")}
                              attribute={bodyshapedescription.bodylookid}
                              mydata={formdata.bodylook}
                            />
                            <MakeRow
                              title={t("Body_Type")}
                              attribute={bodyshapedescription.bodytypeid}
                              mydata={formdata.bodytype}
                            />
                            <MakeRow
                              title={t("Body_Weight")}
                              attribute={bodyshapedescription.bodyweightid}
                              mydata={formdata.bodyweight}
                              description={t("kg")}
                            />
                            <MakeRow
                              title={t("Disability")}
                              attribute={bodyshapedescription.disabilityid}
                              mydata={formdata.disabilitie}
                            />
                            <MakeRow
                              title={t("Ethnicity")}
                              attribute={bodyshapedescription.ethnicityid}
                              mydata={formdata.ethnicitie}
                            />
                            <MakeRow
                              title={t("Eye_Wear")}
                              attribute={bodyshapedescription.eyewearid}
                              mydata={formdata.eyewear}
                            />
                            <MakeRow
                              title={t("Facial_Hair")}
                              attribute={bodyshapedescription.facialhairid}
                              mydata={formdata.facialhair}
                            />
                            <MakeRow
                              title={t("Hair_Color")}
                              attribute={bodyshapedescription.haircolorid}
                              mydata={formdata.haircolor}
                            />
                            <MakeRow
                              title={t("Hair_Type")}
                              attribute={bodyshapedescription.hairtypeid}
                              mydata={formdata.hairtype}
                            />
                          </tbody>
                        </Table>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>

                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <BsPersonLinesFill className="w-5 h-5  text-white" />
                        </div>
                        {t("Personality")}
                      </div>
                      {personalitydescription !== null ? (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Romance_Type")}
                              attribute={personalitydescription.romancetypeid}
                              mydata={formdata.romancetype}
                            />
                            <MakeRow
                              title={t("Characteristics")}
                              attribute={
                                personalitydescription.characteristicsid
                              }
                              mydata={formdata.characteristics}
                            />
                          </tbody>
                        </Table>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <GiBackup className="w-5 h-5  text-white" />
                        </div>
                        {t("Background")}
                      </div>
                      {backgroundandculturalvalues !== null ? (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Convert")}
                              attribute={backgroundandculturalvalues.convertid}
                              mydata={formdata.convert}
                            />
                            <MakeRow
                              title={t("Education")}
                              attribute={
                                backgroundandculturalvalues.educationid
                              }
                              mydata={formdata.education}
                            />
                            <MakeRow
                              title={t("Faith_Attitude")}
                              attribute={
                                backgroundandculturalvalues.faithattitudeid
                              }
                              mydata={formdata.faithattitude}
                            />
                            <MakeRow
                              title={t("Faith")}
                              attribute={backgroundandculturalvalues.faithid}
                              mydata={formdata.faith}
                            />
                            <MakeRow
                              title={t("Family_Values")}
                              attribute={
                                backgroundandculturalvalues.familyvaluesid
                              }
                              mydata={formdata.familyvalue}
                            />
                            <MakeRow
                              title={t("Nationality")}
                              attribute={
                                backgroundandculturalvalues.nationalityid
                              }
                              mydata={formdata.nationality}
                            />
                            <MakeRow
                              title={t("Polygamy")}
                              attribute={backgroundandculturalvalues.polygamyid}
                              mydata={formdata.polygamy}
                            />
                            <MakeRow
                              title={t("Profile_Creator")}
                              attribute={
                                backgroundandculturalvalues.profilecreatorid
                              }
                              mydata={formdata.profilecreator}
                            />
                            <MakeRow
                              title={t("Read_Quran")}
                              attribute={
                                backgroundandculturalvalues.readquranid
                              }
                              mydata={formdata.readquran}
                            />
                            <MakeRow
                              title={t("Religion_Services")}
                              attribute={
                                backgroundandculturalvalues.religionservicesid
                              }
                              mydata={formdata.religionservice}
                            />
                          </tbody>
                        </Table>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                  <div className="w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <FaGlassCheers className="w-5 h-5  text-white" />
                        </div>
                        {t("LifeStyle")}
                      </div>
                      {lifestylesandattitudes !== null ? (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Alcohol_Attitude")}
                              attribute={
                                lifestylesandattitudes.alcoholattitudeid
                              }
                              mydata={formdata.alcoholattitude}
                            />
                            <MakeRow
                              title={t("Animals_Attitude")}
                              attribute={
                                lifestylesandattitudes.animalsattitudeid
                              }
                              mydata={formdata.animalsattitude}
                            />
                            <MakeRow
                              title={t("Annual_Income")}
                              attribute={lifestylesandattitudes.annualincomeid}
                              mydata={formdata.annualincome}
                            />
                            <MakeRow
                              title={t("Children_Have_Attitude")}
                              attribute={
                                lifestylesandattitudes.childrenhaveattitudeid
                              }
                              mydata={formdata.childrenhaveattitude}
                            />
                            {lifestylesandattitudes.childrenhaveattitudeid !==
                              1 && (
                              <>
                                <MakeRow
                                  title={t("Children_Number_Attitude")}
                                  attribute={
                                    lifestylesandattitudes.childrennumberattitudeid
                                  }
                                  mydata={formdata.childrennumberattitude}
                                />
                                <MakeRow
                                  title={t("Children_Oldest_Attitude")}
                                  attribute={
                                    lifestylesandattitudes.childrenoldestattitudeid
                                  }
                                  mydata={formdata.childrenoldestattitude}
                                />
                                <MakeRow
                                  title={t("Children_Youngest_Attitude")}
                                  attribute={
                                    lifestylesandattitudes.childrenyoungesttattitudeidid
                                  }
                                  mydata={formdata.childrenyoungesttattitude}
                                />
                              </>
                            )}

                            <MakeRow
                              title={t("Children_Attitude")}
                              attribute={
                                lifestylesandattitudes.childrenattitudeid
                              }
                              mydata={formdata.childrenattitude}
                            />
                            <MakeRow
                              title={t("Clothing_Attitude")}
                              attribute={
                                lifestylesandattitudes.clothingattitudeid
                              }
                              mydata={formdata.clothingattitude}
                            />
                            <MakeRow
                              title={t("Employment_Attitude")}
                              attribute={
                                lifestylesandattitudes.employmentattitudeid
                              }
                              mydata={formdata.employmentattitude}
                            />
                            <MakeRow
                              title={t("Food_Attitude")}
                              attribute={lifestylesandattitudes.foodattitudeid}
                              mydata={formdata.foodattitude}
                            />
                            <MakeRow
                              title={t("Gambling_Attitude")}
                              attribute={
                                lifestylesandattitudes.gamblingattitudeid
                              }
                              mydata={formdata.gamblingattitude}
                            />
                            <MakeRow
                              title={t("Have_Any_Car")}
                              attribute={lifestylesandattitudes.haveacar}
                              mydata={formdata.haveacar}
                            />
                            <MakeRow
                              title={t("Have_A_Motorcycle")}
                              attribute={lifestylesandattitudes.haveamotorcycle}
                              mydata={formdata.haveamotorcycle}
                            />
                            <MakeRow
                              title={t("Home_Type")}
                              attribute={lifestylesandattitudes.hometypeid}
                              mydata={formdata.hometype}
                            />
                            <MakeRow
                              title={t("Living_Situation")}
                              attribute={
                                lifestylesandattitudes.livingsituationid
                              }
                              mydata={formdata.livingsituation}
                            />
                            <MakeRow
                              title={t("Marital_Status_Attitude")}
                              attribute={
                                lifestylesandattitudes.maritalstatusattitudeid
                              }
                              mydata={formdata.maritalstatusattitude}
                            />
                            <MakeRow
                              title={t("Relocate")}
                              attribute={lifestylesandattitudes.relocateid}
                              mydata={formdata.relocate}
                            />
                            <MakeRow
                              title={t("Residence")}
                              attribute={lifestylesandattitudes.residenceid}
                              mydata={formdata.residence}
                            />
                            <MakeRow
                              title={t("Residency_Status")}
                              attribute={
                                lifestylesandattitudes.residencystatusid
                              }
                              mydata={formdata.residencystatus}
                            />
                            <MakeRow
                              title={t("Smoke_Attitude")}
                              attribute={lifestylesandattitudes.smokeattitudeid}
                              mydata={formdata.smokeattitude}
                            />
                            <MakeRow
                              title={t("Travling_Attitude")}
                              attribute={
                                traveling.length !== 0
                                  ? traveling[0].travlingattitudeid
                                  : null
                              }
                              mydata={formdata.travlingattitude}
                            />
                          </tbody>
                        </Table>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <FaAmericanSignLanguageInterpreting className="w-5 h-5  text-white" />
                        </div>
                        {t("Language")}
                      </div>
                      {spokenlanguages.length !== 0 ? (
                        <div className=" flex items-center flex-wrap justify-center w-full gap-1">
                          {spokenlanguages.map((item, index) => (
                            <MakeEmpRow
                              key={index}
                              gdata={item}
                              mydata={formdata.language}
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <GiHobbitDwelling className="w-5 h-5  text-white" />
                        </div>
                        {t("Hobbies")}
                      </div>
                      {profilehobbies.length !== 0 ? (
                        <div className=" flex items-center flex-wrap justify-center w-full gap-1">
                          {profilehobbies.map((item, index) => (
                            <MakeEmpRow2
                              key={index}
                              gdata={item}
                              mydata={hobbies?.hobbies}
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <MdSportsKabaddi className="w-5 h-5  text-white" />
                        </div>
                        {t("Sport_Attitude")}
                      </div>
                      {sports.length !== 0 ? (
                        <div className=" flex items-center flex-wrap justify-center w-full gap-1">
                          {sports.map((item, index) => (
                            <MakeSportRow
                              key={index}
                              gdata={item}
                              mydata={formdata.sportattitude}
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};
export default PublicProfile;
