import React, { lazy } from "react";
import { Route } from "react-router-dom";
import PublicContact from "../components/publicpage/PublicContact";
import Loader from "../components/Loader";
const Register = lazy(() => import("../components/home/Home"));

const MemberRoutes = () => (
  <>
    <Route path="/" element={<Register />} />
    <Route path="/public-contact" element={<PublicContact />} />
    <Route path="/chat/:status" element={<Loader red={true} />} />
    <Route path="/chat/:status/:inboxid" element={<Loader red={true} />} />
    <Route path="/search/:page/:gender" element={<Loader red={true} />} />
    <Route path="/search/:page" element={<Loader red={true} />} />
    <Route path="/activity/:page" element={<Loader red={true} />} />
    <Route path="/matches/:page" element={<Loader red={true} />} />
    <Route path="/profile/:profileid" element={<Loader red={true} />} />
    <Route path="/profile-settings/view" element={<Loader red={true} />} />
    <Route path="/profile-settings/edit" element={<Loader red={true} />} />
    <Route path="/profile-settings/delete" element={<Loader red={true} />} />
    <Route path="/profile-settings/photos" element={<Loader red={true} />} />
    <Route
      path="/profile-settings/image-access"
      element={<Loader red={true} />}
    />
    <Route path="/change-email-receipt" element={<Loader red={true} />} />
    <Route path="/profile-settings/agent" element={<Loader red={true} />} />
    <Route path="/payment" element={<Loader red={true} />} />
    <Route path="/payment/cancel" element={<Loader red={true} />} />
    <Route path="/memberssettings/:page" element={<Loader red={true} />} />
    <Route path="/admin/:page" element={<Loader red={true} />} />
    <Route
      path="/changeemailconfirm/:userid/:email/:token"
      element={<Loader red={true} />}
    />
    <Route
      path="/confirmemail/:userId/:token"
      element={<Loader red={true} />}
    />
    <Route path="/payment-success" element={<Loader red={true} />} />
    <Route path="/payment-wrong" element={<Loader red={true} />} />
    {/* <Route path="/profile-dashboard" element={<Loader red={true} />} /> */}
    <Route path="/dashboard" element={<Loader red={true} />} />
    <Route path="/welcomepage" element={<Loader red={true} />} />
    <Route path="/login" element={<Loader red={true} />} />
    <Route path="/successStories" element={<Loader red={true} />} />
  </>
);

export default MemberRoutes;
