import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import { MainContent } from "../../App";
import { useTranslation } from "react-i18next";
import { url } from "../../api/Api";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Recaptcha from "react-recaptcha";
import fetchHandler from "../../api/fetchHandler";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import { customToasterStyle } from "../../utils/constants";
import FixedHeader from "../../layout/FixedHeader";

const PublicContact = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { language, changnow, openReg } = useContext(MainContent);
  const [contactpage, setContactpage] = useState(null);
  const [contactData, setContactData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [contactid, setContactid] = useState(0);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [deviceId, setDeviceId] = useState(0);
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const recaptchaRef = useRef(null);

  const handleRecaptchaVerify = (token) => {
    setRecaptchaToken(token);
  };

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  let myHeaders = new Headers();
  myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept-Language", language || "en");

  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scroll({ top: 0, behavior: "smooth" });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const getContactPage = () => {
    fetchHandler(
      `${url}sitepage/pagetype?PageTypeId=45&LangCode=${language}`,
      "GET",
      undefined,
      myHeaders
    ).then((result) => {
      setContactpage(result.data);
    });
  };

  const contactType = () => {
    fetchHandler(
      `${url}metadata/public/singletype?PublicLookupSingleTypeOptions=CorrespondenceType`,
      "GET",
      undefined,
      myHeaders
    ).then((result) => {
      if (result.correspondencetype) {
        const filterData = result.correspondencetype.filter(
          (item) => item.ispublic === true
        );
        setContactData(filterData);
      }
    });
  };
  const DeviceType = () => {
    fetchHandler(
      `${url}metadata/public/singletype?PublicLookupSingleTypeOptions=DeviceType`,
      "GET",
      undefined,
      myHeaders
    ).then((result) => {
      setDeviceData(result.devicetype);
    });
  };

  const showToast = (result) => {
    if (result.success) {
      toast.success(result.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };
  const contactUs = () => {
    setIsSubmitting(true);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");
    let raw = JSON.stringify({
      email: email,
      Text: text,
      correspondenceTypeId: contactid,
      title: title,
      deviceTypeId: deviceId,
      langcode: language || "en",
      recaptchaToken: recaptchaToken,
    });
    fetchHandler(
      `${url}Correspondence/public/create`,
      "POST",
      raw,
      myHeaders
    ).then((result) => {
      if (result.success) {
        history("/contact-receipt");
      }
      setIsSubmitting(false);
      resetRecaptcha();
      showToast(result);
    });
  };

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    DeviceType();
    contactType();
    getContactPage();
  }, [language, changnow]);

  // create a reset function
  const resetRecaptcha = () => {
    setVerified(false);

    if (recaptchaRef.current !== null) {
      recaptchaRef.current.reset();
    }

    setIsSubmitting(false);
  };

  return (
    <>
      {contactData.length !== 0 ? (
        <div className="min-h-screen bg-blue-50 container pt-24">
          <div className=" w-full flex items-center mb-0 justify-center ">
            <h1 className="text-center mt-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
              <BsBlockquoteLeft className="text-sr" />
              {t("Contact")}
              <BsBlockquoteRight className="text-sr" />
            </h1>
          </div>
          <FixedHeader
            scrollPosition={scrollPosition}
            headerText={t("Contact")}
            language={language}
          />
          <ToastContainer />
          {contactpage !== null && (
            <div className="pt-4">
              <div
                className="bg-white py-5 px-5 w-full shadow-md rounded-lg font-normal"
                dangerouslySetInnerHTML={{ __html: contactpage.text }}
              ></div>
            </div>
          )}
          <div className="p-3 bg-white rounded-md shadow-md  w-full mt-4 mb-32">
            <div className="pt-4">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  contactUs();
                }}
              >
                <h6 className="font-bold">
                  {t("Subject")} <span className="ml-1 text-red-600">*</span>
                </h6>
                <select
                  onChange={(e) => setContactid(e.target.value)}
                  required
                  onInvalid={(e) => {
                    window.scroll({ top: 0, behavior: "smooth" });
                    e.target.setCustomValidity(
                      t("Select_A_Value_from_Topic_List")
                    );
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                  // className="p-2 border rounded-md  mt-2 outline-none my-1 w-full"
                  className="p-2 shadow-md font-medium text-sr rounded-md text-sm bg-white  mt-2 outline-none py-1 px-2 border  my-1 w-full"
                >
                  <option value="">{t("Select_Contact_Subject")}</option>
                  {contactData.map((item, ind) => (
                    <option value={item.translistid} key={ind}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <h6 className="pt-3 font-bold">
                  {t("Device_Type")}{" "}
                  <span className="ml-1 text-red-600">*</span>
                </h6>
                <select
                  onChange={(e) => setDeviceId(e.target.value)}
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      t("Select_A_Value_from_Device_List")
                    );
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                  // className="p-1 border rounded-md  mt-2 outline-none w-64"
                  className="p-2 shadow-md font-medium text-sr rounded-md text-sm bg-white  mt-2 outline-none py-1 px-2 border  my-1 w-full"
                >
                  <option value="">{t("Select_Device_Type")}</option>
                  {deviceData.map((item, ind) => (
                    <option value={item.translistid} key={ind}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <h6 className="pt-3 font-bold">
                  {t("Email")} <span className="ml-1 text-red-600">*</span>
                </h6>
                <input
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                  className="py-1 px-2 mt-2 rounded-md w-full border outline-none "
                  placeholder={t("Your_Email")}
                  type="email"
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      t("Enter_A_valid_Email_Address")
                    );
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                  style={{ direction: email ? "ltr" : "" }}
                />
                <h6 className="pt-3 font-bold">
                  {t("Title")} <span className="ml-1 text-red-600">*</span>
                </h6>
                <input
                  maxLength="100"
                  onChange={(e) => setTitle(e.target.value)}
                  className="py-1 px-2 mt-2 w-full rounded-md border outline-none"
                  placeholder={t("Subject_Title")}
                  type="text"
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      t("The_Subject_Field_Is_Mandatory")
                    );
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                />
                <div className=" flex w-full items-end justify-between ">
                  <h6 className="pt-3 font-bold">
                    {t("Message_Content")}{" "}
                    <span className="ml-1 text-red-600">*</span>
                  </h6>
                </div>
                <textarea
                  maxLength="4000"
                  onChange={(e) => setText(e.target.value)}
                  required
                  onInvalid={(e) => {
                    e.target.setCustomValidity(
                      t("The_content_Field_Is_Mandatory")
                    );
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                  onInput={(e) => e.target.setCustomValidity("")}
                  className="py-1 px-2 mt-2 w-full rounded-md border outline-none"
                  placeholder={t("Content")}
                  cols="30"
                  rows="5"
                ></textarea>
                <div>
                  <p className=" text-green-500 font-medium text-xs font-bold">
                    {text.length} / 4000
                  </p>
                </div>
                {openReg === false && (
                  <div className="mt-3 flex items-center">
                    <Recaptcha
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPCHA_KEY}
                      render="explicit"
                      elementID="public_Contact"
                      hl={language}
                      verifyCallback={(token) => {
                        setVerified(true);
                        handleRecaptchaVerify(token);
                      }}
                      onErrored={() => {
                        resetRecaptcha();
                      }}
                    />
                  </div>
                )}
                {verified ? (
                  <div className="flex items-center justify-center md:justify-between mb-4 mt-3">
                    <button
                      type="submit"
                      className="px-2 py-2 rounded-md bg-sr text-white hover:bg-pr  text-md font-bold"
                      disabled={isSubmitting}
                    >
                      {t("Submit")}
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center justify-center md:justify-between mb-4 mt-3">
                    <button
                      disabled
                      type="submit"
                      className="px-2 py-2 cursor-not-allowed rounded-md bg-gray-400 text-white border text-md font-bold"
                    >
                      {t("Submit")}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default PublicContact;
