import { useEffect } from "react";
// import { HANDLE_API_REQUEST } from "../api/Api";
// import { endpoints } from "../api/EndPoints";
import endpoints, { HANDLE_API_REQUEST } from "../api/EndPoints";

const MaintenanceChecker = () => {
  useEffect(() => {
    const checkMaintenance = async () => {
      if (sessionStorage.getItem("maintenanceChecked")) return;

      try {
        const response = await HANDLE_API_REQUEST.GET(
          endpoints.GET_MAINTENANCE_STATUS
        );
        const data = await response.json();

        sessionStorage.setItem("maintenanceChecked", "true");

        if (data.maintenance) {
          window.location.href = "/maintenance";
        }
      } catch (error) {
        console.error("Error fetching maintenance status:", error);
      }
    };

    checkMaintenance();
  }, []);

  return null; // Ingen UI, den kører kun logikken
};

export default MaintenanceChecker;
