import Cookies from "js-cookie";
import { url } from "./Api";

export const METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const endpoints = {
  CHANGE_GENDER: "profile/changegender",
  GET_ALL_HOBBIES: "metadata/lookups?LookupOptions=Hobbies",
  GET_PROFILE_DATA: "metadata/lookups?LookupOptions=Profile",
  GET_USER_AGE: "metadata/singletype?LookupSingleTypeOptions=Age",
  SAVE_CONTENT_DRAFT: "profile/savecontentdraft",
  SAVE_LOCATION: "profile/savelocation",
  SAVE_BODY_SHAPE: "profile/savebodyshape",
  SAVE_PERSONALITY: "profile/savepersonality",
  SAVE_LANGUAGE: "profile/savespokenlanguages",
  SAVE_LIFE_STYLE: "profile/savelifestyle",
  SAVE_BACKGROUND_CULTURE: "profile/savebackgroundAndCultue",
  SAVE_SPORTS: "profile/saveprofilesports",
  SAVE_TRAVELLING: "profile/savesprofiletravlingattitude",
  SAVE_HOBBIES: "profile/savehobbies",
  REFRESH_TOKEN: "identity/refreshtoken",
  GET_OWN_DATA: "profile/own",
  GET_MAINTENANCE_STATUS: "maintenance/status",
  SAVE_ONESIGNAL_PUSHNOTIFICATION: "fcm/addorupdate",
  DELETE_ONESIGNAL_PUSHNOTIFICATION: "fcm/delete",
  NEW_MESSAGE: "inbox/totalunread",
  ACTIVATED_NOTIFICATION: "notifications/activatediactivate",
  NOTIFICATIONS_SCHEDULER:
    "metadata/singletype?LookupSingleTypeOptions=NotificationScheduler",
  NOTIFICATIONS: "notifications",
  CONFIRM_EMAIL: (language) =>
    `sitepage/pagetype?PageTypeId=43&LangCode=${language}`,
  CONFIRM_EMAIL_MSG: (language) =>
    `sitepage/pagetype?PageTypeId=47&LangCode=${language}`,
  SUBSCRIPTION_DATA: (language) =>
    `metadata/lookups?LookupOptions=subscription&calture=${language}`,
  PAYMENT_AMOUNT: (language, currencyId) =>
    `metadata/lookups/subscription?LangCode=${language}&CurrencyId=${currencyId}`,
  PAYMENT_DATA: `metadata/lookups?LookupOptions=Payment`,
  BASIC_DATA: (language) =>
    `sitepage/pagetype?PageTypeId=16&LangCode=${language}`,
  GOLD_DATA: (language) =>
    `sitepage/pagetype?PageTypeId=17&LangCode=${language}`,
  PLATINUM_DATA: (language) =>
    `sitepage/pagetype?PageTypeId=18&LangCode=${language}`,
};

export function getConfig(language, signal) {
  let getheders = new Headers();
  language && getheders.append("Accept-Language", language || "en");
  getheders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  const requestOptions = {
    method: "GET",
    headers: getheders,
    redirect: "follow",
    signal: signal ? signal : null,
  };
  return requestOptions;
}
export function getApiKeyConfig() {
  let myHeaders = new Headers();
  myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return requestOptions;
}

export function mutationConfig(body, method, language = null) {
  let mutationHeader = new Headers();
  mutationHeader.append(
    "Authorization",
    `Bearer ${Cookies.get("accessToken")}`
  );
  language && mutationHeader.append("Accept-Language", language || "en");
  mutationHeader.append("Content-Type", "application/json");

  const requestOptions = {
    method: method,
    headers: mutationHeader,
    body: body,
    redirect: "follow",
  };
  return requestOptions;
}

export const HANDLE_API_REQUEST = {
  GET: async (endpoint, language, config, signal) =>
    await fetch(url + endpoint, config || getConfig(language, signal)),
  POST: async (endpoint, body, config = null, language = null) =>
    await fetch(
      url + endpoint,
      config || mutationConfig(body, METHODS.POST, language)
    ),
  PUT: async (endpoint, body) =>
    await fetch(url + endpoint, mutationConfig(body, METHODS.PUT)),
  DELETE: async (endpoint, body) =>
    await fetch(url + endpoint, mutationConfig(body, METHODS.DELETE)),
  PATCH: async (endpoint, body) =>
    await fetch(url + endpoint, mutationConfig(body, METHODS.PATCH)),
};

export default endpoints;
